import { Component, OnInit } from '@angular/core';
import { Field } from '../../../data-models/field';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldService } from '../../services/field.service';
import { FarmService } from '../../services/farm.service';
import { Farm } from '../../../data-models/farm';

@Component({
  selector: 'app-field-detail',
  templateUrl: './field-detail.component.html',
  styleUrls: ['./field-detail.component.css']
})
export class FieldDetailComponent implements OnInit {
  pageTitle = 'Field Detail';
  errorMessage = '';
  field: Field | undefined;
  farm: Farm | undefined;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private farmService: FarmService,
    private fieldService: FieldService) {
  }

  ngOnInit() {
    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      this.getField(id);
      this.getFarm(this.field.farmId);
    }
  }

  getField(id: number) {
    this.fieldService.getField(id).subscribe({
      next: field => this.field = field,
      error: err => this.errorMessage = err
    });
  }
  getFarm(id: number) {
    this.farmService.getFarm(id).subscribe({
      next: farm => this.farm = farm,
      error: err => this.errorMessage = err
    });
  }
  onBack(): void {
      this.router.navigate(['dashboard/field']);
  }

}
