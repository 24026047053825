import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { RootComponent } from './root/root.component';
import { HomeComponent } from './home/home.component';
import { SettingsComponent } from './settings/settings.component';

import { AuthGuard } from '../auth.guard';
import { TraceComponent } from './pages/trace/trace.component';
import { FieldComponent } from './pages/field/field.component';
import { FarmComponent } from './pages/farm/farm.component';
import { FarmEditComponent } from './pages/farm/farm.edit.component';
import { FarmDetailComponent } from './pages/farm/farm.detail.component';
import { FarmEditGuard } from './pages/farm/farm-edit.guard';
import { FieldEditComponent } from './pages/field/field-edit.component';
import { FieldDetailComponent } from './pages/field/field-detail.component';
import { TraceDetailComponent } from './pages/trace/trace-detail.component';
import { TraceEditComponent } from './pages/trace/trace-edit.component';
import { ProductComponent } from './pages/product/product.component';
import { ProductEditGuard } from './pages/product/product-edit.guard';
import { ProductEditComponent } from './pages/product/product-edit.component';
import { ProductDetailComponent } from './pages/product/product-detail.component';
import { VehicleEditComponent } from './pages/vehicle/vehicle-edit.component';
import { VehicleDetailComponent } from './pages/vehicle/vehicle-detail.component';
import { EmployeetripComponent } from './pages/employeetrip/employeetrip.component';
import { EmployeetripEditComponent } from './pages/employeetrip/employeetrip-edit.component';
import { EmployeetripDetailComponent } from './pages/employeetrip/employeetrip-detail.component';
import { EmployeeDetailComponent } from './pages/employee/employee-detail.component';
import { EmployeeEditComponent } from './pages/employee/employee-edit.component';
import { EmployeeComponent } from './pages/employee/employee.component';
import { EmployeeEditGuard } from './pages/employee/employee-edit.guard';
import { VehicleComponent } from './pages/vehicle/vehicle.component';
import { VehicleEditGuard } from './pages/vehicle/vehicle.guard';
import { EmployeetripGuard } from './pages/employeetrip/employeetrip.guard';
import { VehicleModelComponent } from './pages/vehicle-model/vehicle-model.component';
import { VehicleModelEditComponent } from './pages/vehicle-model/vehicle-model-edit.component';
import { VehicleModelDetailComponent } from './pages/vehicle-model/vehicle-model-detail.component';
import { VehicleModelEditGuard } from './pages/vehicle-model/vehicle-model-edit.guard';
import { VehicleMakeEditGuard } from './pages/vehicle-make/vehicle-make-edit.guard';
import { VehicleMakeEditComponent } from './pages/vehicle-make/vehicle-make-edit.component';
import { VehicleMakeDetailComponent } from './pages/vehicle-make/vehicle-make-detail.component';
import { VehicleMakeComponent } from './pages/vehicle-make/vehicle-make.component';


export const routing: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'dashboard',
        component: RootComponent, canActivate: [AuthGuard],

        children: [
            { path: '', component: HomeComponent },
            { path: 'home', component: HomeComponent },
            { path: 'settings', component: SettingsComponent },
            { path: 'farm', component: FarmComponent },
            { path: 'farm/0/edit', component: FarmEditComponent },
            { path: 'farm/:id', component: FarmDetailComponent },
            {
                path: 'farm/:id/edit',
                canDeactivate: [FarmEditGuard],
                component: FarmEditComponent
            },
            { path: 'field', component: FieldComponent },
            { path: 'fields/:id', component: FieldDetailComponent },
            { path: 'fields/:id/edit', component: FieldEditComponent },
            { path: 'trace', component: TraceComponent },
            { path: 'traces/:id', component: TraceDetailComponent },
            { path: 'traces/:id/edit', component: TraceEditComponent },
            { path: 'products', component: ProductComponent },
            { path: 'products/0/edit', component: ProductEditComponent },
            { path: 'products/:id', component: ProductDetailComponent },
            {
                path: 'products/:id/edit',
                canDeactivate: [ProductEditGuard],
                component: ProductEditComponent
            },
            { path: 'employee', component: EmployeeComponent },
            { path: 'employee/0/edit', component: EmployeeEditComponent },
            { path: 'employee/:id', component: EmployeeDetailComponent },
            {
                path: 'employee/:id/edit',
                canDeactivate: [EmployeeEditGuard],
                component: EmployeeEditComponent
            },
            { path: 'employeetrip', component: EmployeetripComponent },
            { path: 'employeetrip/0/edit', component: EmployeetripEditComponent },
            { path: 'employeetrip/:id', component: EmployeetripDetailComponent },
            {
                path: 'employeetrip/:id/edit',
                canDeactivate: [EmployeetripGuard],
                component: EmployeetripEditComponent
            },
            { path: 'vehicle', component: VehicleComponent },
            { path: 'vehicle/0/edit', component: VehicleEditComponent },
            { path: 'vehicle/:id', component: VehicleDetailComponent },
            {
                path: 'vehicle/:id/edit',
                canDeactivate: [VehicleEditGuard],
                component: VehicleEditComponent
            },
            { path: 'vehicleMake', component: VehicleMakeComponent },
            { path: 'vehicleMake/0/edit', component: VehicleMakeEditComponent },
            { path: 'vehicleMake/:id', component: VehicleMakeDetailComponent },
            {
                path: 'vehicleMake/:id/edit',
                canDeactivate: [VehicleMakeEditGuard],
                component: VehicleMakeEditComponent
            },
            { path: 'vehicleModel', component: VehicleModelComponent },
            { path: 'vehicleModel/0/edit', component: VehicleModelEditComponent },
            { path: 'vehicleModel/:id', component: VehicleModelDetailComponent },
            {
                path: 'vehicleModel/:id/edit',
                canDeactivate: [VehicleModelEditGuard],
                component: VehicleModelEditComponent
            },
        ]
    }
]);

