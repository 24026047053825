import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Trace } from 'src/app/data-models/trace';
import { Field } from 'src/app/data-models/field';
import { TraceService } from '../../services/trace.service';
import { FieldService } from '../../services/field.service';

@Component({
  selector: 'app-trace-detail',
  templateUrl: './trace-detail.component.html',
  styleUrls: ['./trace-detail.component.css']
})
export class TraceDetailComponent implements OnInit {
  pageTitle = 'Trace Detail';
  errorMessage = '';
  trace: Trace ;
  field: Field ;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private traceService: TraceService,
    private fieldService: FieldService) {
  }

  ngOnInit() {
    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      this.getTrace(id);
      //debugger;
      //this.getField(parseInt(this.trace.fieldId));
    }
  }

  getField(id: number) {
    this.fieldService.getField(id).subscribe({
      next: field => this.field = field,
      error: err => this.errorMessage = err
    });
  }
  getTrace(id: number) {
    this.traceService.getTrace(id).subscribe({
      
      next: trace => this.trace = trace,
      error: err => this.errorMessage = err
    });
    
  }
  onBack(): void {
    this.router.navigate(['dashboard/trace']);
  }

}
