import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpXhrBackend} from '@angular/common/http';

import { UserRegistration } from '../models/user.registration.interface';
import { Jwt } from '../models/token.interface';
import { ConfigService } from '../utils/config.service';

import {BaseService} from "./base.service";

import { Observable, of as observableOf, of, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs'; 

// Add the RxJS Observable operators we need in this app.
//import '../../rxjs-operators';
import { tap, catchError, map, finalize } from 'rxjs/operators';
import { InterceptorService } from '../utils/Interceptor.service';
import { Company } from '../../data-models/company';

@Injectable()

export class UserService extends BaseService {

  baseUrl: string = '';

  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();

  private loggedIn = false;
  response: Observable<JSON>;

  constructor(private http: HttpClient, private configService: ConfigService,private backend: HttpXhrBackend) {
    super();
    this.loggedIn = !!localStorage.getItem('auth_token');
    // ?? not sure if this the best way to broadcast the status but seems to resolve issue on page refresh where auth status is lost in
    // header component resulting in authed user nav links disappearing despite the fact user is still logged in
    this._authNavStatusSource.next(this.loggedIn);
    this.baseUrl = configService.getApiURI();
  }

    register(email: string, password: string, firstName: string, lastName: string, location: string,
        company: Company, employeeNumber: string, cellphoneNumber:string, uID:string     ): Observable<UserRegistration> {
    let body = JSON.stringify({ email, password, firstName, lastName,location,company,employeeNumber,cellphoneNumber,uID });
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //let options = new RequestOptions({ headers: headers });

      return this.http.post(this.baseUrl + "/accounts", body, { headers: headers })
        .pipe(map((response: Response) => ((<any>response)) as UserRegistration),
          catchError(this.handleError)
        );
      
     
  }   

  login(userName, password){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
      //const req = new HttpRequest('POST', this.baseUrl + '/auth/login',JSON.stringify({ userName, password }) );
    // const iHandler = new HttpInterceptorHandler(this.backend, new InterceptorService());
    //iHandler.handle(req);
    return this.http.post(this.baseUrl + '/auth/login', JSON.stringify({ userName, password }), { headers })
      .pipe(
            map((response: Response) => ((<any>response)) as Jwt)
          , catchError(this.handleError)
          , finalize(() => {
            this.loggedIn = true;
            this._authNavStatusSource.next(true);


          })
        );
    
  }

  logout() {
    localStorage.removeItem('auth_token');
    this.loggedIn = false;
    this._authNavStatusSource.next(false);
  }

  isLoggedIn() {
    return this.loggedIn;
  }

 
  facebookLogin(accessToken:string) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let body = JSON.stringify({ accessToken });  
    return this.http
      .post(
        this.baseUrl + '/externalauth/facebook', body, { headers }).pipe(
          tap(data => JSON.stringify(data)),
            catchError(this.handleLoginError)
            
          );
      
    }
    private handleLoginError(err) {
        
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error !== undefined) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.login_failure}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }
}

