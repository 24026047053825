import { Injectable } from '@angular/core';
import { throwError, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Trace } from 'src/app/data-models/trace';

@Injectable()
export class TraceService {
    private tracesUrl = 'api/traces';

    constructor(private http: HttpClient, ) { }
    getTraces(): Observable<Trace[]> {
        return this.http.get<Trace[]>(this.tracesUrl + '/GetTraces')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    getTrace(id: number): Observable<Trace> {
        if (id === 0) {
            return of(this.initializeTrace());
        }
        const url = `${this.tracesUrl}/${id}`;

        return this.http.get<Trace>(url)
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    createTrace(trace: Trace): Observable<Trace> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        trace.id = null;
        return this.http.post<Trace>(this.tracesUrl + '/SaveTrace', JSON.stringify(trace), { headers })
            .pipe(
                tap(data => console.log('createTrace: ' + JSON.stringify(data))),
                catchError(this.handleError)
            );
    }

    deleteTrace(id: number): Observable<{}> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.tracesUrl}/${id}`;
        return this.http.delete<Trace>(url, { headers })
            .pipe(
                tap(data => console.log('deleteTrace: ' + id)),
                catchError(this.handleError)
            );
    }

    updateTrace(trace: Trace): Observable<Trace> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.tracesUrl}/${trace.id}`;
        return this.http.put<Trace>(url, trace, { headers })
            .pipe(
                tap(() => console.log('updateField: ' + trace.id)),
                // Return the field on an update
                map(() => trace),
                catchError(this.handleError)
            );
    }

    private handleError(err) {
        debugger;
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }

    private initializeTrace(): Trace {
        // Return an initialized object
        return {
            id: 0,
            fieldId: null,
            code: null,
            plantSpacing: null,
            irrigationTypeId: null,
            fieldName: null,
            fieldCode: null,
            irrigation: null,
            size: null,
            bedLength: null,
            bedQuantity: null,
            bedSpacing:null

        };
    }
}
