import { Component, OnInit } from '@angular/core';
import { Field } from '../../../data-models/field';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeTrip } from '../../../data-models/employeetrip';
import { EmployeeTripService } from '../../services/employeetrip.service';
import { ConfigService } from '../../../shared/utils/config.service';

@Component({
  selector: 'app-employeetrip-detail',
  templateUrl: './employeetrip-detail.component.html',
  styleUrls: ['./employeetrip-detail.component.css']
})
export class EmployeetripDetailComponent implements OnInit {
    pageTitle = 'Employee Detail';
    errorMessage = '';
    employeeTrip: EmployeeTrip | undefined;
    url: string;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private employeeTripService: EmployeeTripService ,
        private configService: ConfigService) {
        this.url = configService.getBaseURI();
    }

    ngOnInit() {
        const param = this.route.snapshot.paramMap.get('id');
        if (param) {
            const id = +param;
            this.getEmployeeTrip(id);

        }
    }

    getEmployeeTrip(id: number) {
        this.employeeTripService.getEmployeeTrip(id).subscribe({
            next: model => {
                
                this.employeeTrip = model;
                this.employeeTrip.value = (+model.price * +model.quantity).toString();
                
            },
            error: err => this.errorMessage = err
        });
    }
    onBack(): void {
        this.router.navigate(['/dashboard/employeetrip']);
    }
    openImage(link: any) {
        

        const myWindow = window.open(
            this.url +"/" + link,
            '_blank',
            'width=900, height=240'
        );
    }

}
