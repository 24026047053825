import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { VehicleModelEditComponent } from './vehicle-model-edit.component';

@Injectable({
  providedIn: 'root'
})
export class VehicleModelEditGuard implements CanDeactivate<VehicleModelEditComponent> {
    canDeactivate(component: VehicleModelEditComponent): Observable<boolean> | Promise<boolean> | boolean {
        if (component.vehicleModelForm.dirty) {
            const vehicleModel = component.vehicleModelForm.get('name').value || 'New Vehicle Model';
            return confirm(`Navigate away and lose all changes to ${vehicleModel}?`);
        }
        return true;
    }
}