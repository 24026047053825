import { Component, OnInit, ViewChildren, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName, FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Employee } from '../../../data-models/employee';
import { Subscription, Observable, fromEvent, merge } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployeeService } from '../../services/employee.service';
import { GenericValidator } from '../../../shared/generic-validator';
import { debounceTime } from 'rxjs/operators';
import { debug } from 'util';
import { Company } from '../../../data-models/company';
import { CompanyService } from '../../../shared/services/company.service';
import { Gender } from '../../../data-models/gender';
import { DatePipe, KeyValue } from '@angular/common';

@Component({
    selector: 'app-employee-edit',
    templateUrl: './employee-edit.component.html',
    styleUrls: ['./employee-edit.component.css']
})
export class EmployeeEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    pageTitle = 'Employee Edit';
    errorMessage: string;
    employeeForm: FormGroup;
    companies: Company[];
    employee: Employee;
    genderTypes: Gender;
    selectedCompany: Company;
    genderId: FormControl;
    private sub: Subscription;

    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;

    get tags(): FormArray {
        return this.employeeForm.get('tags') as FormArray;
    }

    constructor(private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private employeeService: EmployeeService,
        private companyService: CompanyService,
        public datepipe: DatePipe
        ) {

        // Defines all of the validation messages for the form.
        // These could instead be retrieved from a file or database.
        this.validationMessages = {
            firstName: {
                required: 'Employee first name is required.',
                minlength: 'Employee first name must be at least three characters.',
                maxlength: 'Employee first name cannot exceed 100 characters.'
            },
            surname: {
                required: 'Employee surname is required.',
                minlength: 'Employee surname must be at least three characters.',
                maxlength: 'Employee surname cannot exceed 100 characters.'
            },
            employeeNumber: {
                required: 'Employee number is required.'
            },
            companyId: {
                required: 'Company must be selected.'
            },
            gender: {
                required: 'At least one gender must be selected.'
            }
          
        };

        // Define an instance of the validator for use with this form,
        // passing in this form's set of validation messages.
        this.genericValidator = new GenericValidator(this.validationMessages);

    }

    ngOnInit(): void {
        this.employeeForm = this.fb.group({
            firstName: ['', [Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100)]],
            surname: ['', [Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100)]],
            employeeNumber: ['', Validators.required],
            companyId: ['', Validators.required],
            companyName: '',
            jobTitle: '',
            dateOfBirth: '',
            gender: new FormControl('', Validators.required),
            id: 0
        });
        this.genderId = this.employeeForm.controls.gender as FormControl;

        // Read the employee Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
            params => {
                // Get a List of Companies
                this.companyService.getCompanies().subscribe(
                    companies => {

                        this.companies = companies;
                        this.selectedCompany = this.companies[0];

                    });

                const id = +params.get('id');
                this.getEmployee(id);
            }
        );
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    ngAfterViewInit(): void {
        // Watch for the blur event from any input element on the form.
        // This is required because the valueChanges does not provide notification on blur
        const controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

        // Merge the blur event observable with the valueChanges observable
        // so we only need to subscribe once.
        merge(this.employeeForm.valueChanges, ...controlBlurs).pipe(
            debounceTime(800)
        ).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.employeeForm);
        });
    }


    getEmployee(id: number): void {
        this.employeeService.getEmployee(id)
            .subscribe({
                next: (employee: Employee) => {
                    this.displayEmployee(employee);
                    if (employee.companyId > 0) {
                        this.getCompanyService(employee.companyId);
                        this.changeCompany(employee.companyId);

                    }

                },
                error: err => this.errorMessage = err
            });
    }
    getCompanyService(id: number) {
        if (id > 0) {

            this.companyService.getCompany(id)
                .subscribe({
                    next: (company: Company) => this.selectedCompany = company,
                    error: err => this.errorMessage = err
                });
        }
    }

    // Choose company using select dropdown
    changeCompany(companyId) {
        
        if (companyId > 0 && this.companies !== undefined) {
            this.selectedCompany = null;
            for (var i = 0; i < this.companies.length; i++) {
                if (this.companies[i].id == companyId) {
                    this.selectedCompany = this.companies[i];
                }
            }

            if (this.employeeForm !== null) {
                this.employeeForm.get("companyId").setValue(companyId, {
                    onlySelf: true
                });
                //this.employeeForm.get("company").setValue(this.selectedCompany.name, {
                //    onlySelf: true
                //});
            }
        }

    }

    displayEmployee(employee: Employee): void {
        if (this.employeeForm) {
            this.employeeForm.reset();
        }
        this.employee = employee;

        if (this.employee.id === 0) {
            this.pageTitle = 'Add Employee';
        } else {
            this.pageTitle = `Edit Employee: ${this.employee.firstName} ${this.employee.surname}`;
        }
        // Update the data on the form
        this.employeeForm.patchValue({
            firstName: this.employee.firstName,
            employeeNumber: this.employee.employeeNumber,
            surname: this.employee.surname,
            companyId: this.employee.companyId,
            companyName: this.employee.companyName,
            jobTitle: this.employee.jobTitle,
            gender: this.employee.gender,
            dateOfBirth: this.datepipe.transform(this.employee.dateOfBirth, 'yyyy-MM-dd'),
            id: this.employee.id
        });
        
    }

    deleteEmployee(): void {
        if (this.employee.id === 0) {
            // Don't delete, it was never saved.
            this.onSaveComplete();
        } else {
            if (confirm(`Really delete the employee: ${this.employee.firstName} ${this.employee.surname}?`)) {
                this.employeeService.deleteEmployee(this.employee.id)
                    .subscribe({
                        next: () => this.onSaveComplete(),
                        error: err => this.errorMessage = err
                    });
            }
        }
    }

    saveEmployee(): void {
        debugger;
        if (this.employeeForm.valid) {
            if (this.employeeForm.dirty) {
                const p = { ...this.employee, ...this.employeeForm.value };

                if (p.id === 0 || p.id === null) {
                    if (p.companyId === null) { p.companyId = 1 }
                    p.gender = p.genderId;
                    this.employeeService.createEmployee(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                } else {
                    if (p.companyId === undefined) {
                        p.companyId = this.selectedCompany.id;
                        p.companyName = this.selectedCompany.name;
                    }
                    this.employeeService.updateEmployee(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                }
            } else {
                this.onSaveComplete();
            }
        } else {
            this.errorMessage = 'Please correct the validation errors.';
        }
    }

    onSaveComplete(): void {
        // Reset the form to clear the flags
        this.employeeForm.reset();
        this.router.navigate(['/dashboard/employee']);
    }
}
