import { Component, OnInit } from '@angular/core';
import { Field } from '../../../data-models/field';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../services/employee.service';
import { Employee } from '../../../data-models/employee';
import { Company } from '../../../data-models/company';
import { CompanyService } from '../../../shared/services/company.service';
import { Gender } from '../../../data-models/gender';

@Component({
    selector: 'app-employee-detail',
    templateUrl: './employee-detail.component.html',
    styleUrls: ['./employee-detail.component.css']
})
export class EmployeeDetailComponent implements OnInit {
    pageTitle = 'Employee Detail';
    errorMessage = '';
    employee: Employee | undefined;
    company: Company | undefined;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private employeeService: EmployeeService,
        private companyService: CompanyService) {
    }

    ngOnInit() {
        const param = this.route.snapshot.paramMap.get('id');
        if (param) {
            const id = +param;
            this.getEmployee(id);

        }
    }

    getEmployee(id: number) {
        this.employeeService.getEmployee(id).subscribe({
            next: model => {
                debugger;
                this.employee = model;
                this.employee.gender = Gender[model.gender];
                this.getCompany(model.companyId);
            },
            error: err => this.errorMessage = err
        });
    }
    getCompany(id: number) {
        this.companyService.getCompany(id).subscribe({
            next: company => {
                this.company = this.company;
                this.employee.companyName = company.name;
            },
            error: err => this.errorMessage = err
        });
    }
    onBack(): void {
        this.router.navigate(['/dashboard/employee']);
    }

}
