import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
      var customMetaOption = this.getMeta(req);
      let headersConfig = {};
      if (!customMetaOption) {
          headersConfig = {
              'Content-Type': 'application/json'
          };
      }
      const modified = req.clone({ setHeaders: headersConfig });

      //const modified = req.clone({ setHeaders: { 'Content-Type': 'application/json' } });
      return next.handle(modified);
  }
    getMeta(req: HttpRequest<any>) {
        if (req.headers.has('isfile'))
            return true;
        else
            return false;
    }
}
@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       const authReq = req.clone({ setHeaders: { 'Authorization': 'Basic YWxhZGRpbjpvcGVuc2VzYW1l' } });
    return next.handle(authReq);
  }
}
