import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from '../../data-models/company';

import { UserRegistration } from '../../shared/models/user.registration.interface';
import { CompanyService } from '../../shared/services/company.service';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'app-registration-form',
    templateUrl: './registration-form.component.html',
    styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {

    errors: string;
    isRequesting: boolean;
    submitted: boolean = false;
    companies: Company[];

    selectedCompany: Company;
    constructor(private userService: UserService
        , private router: Router
        , private companyService: CompanyService) { }

    ngOnInit() {
        this.companyService.getCompanies().subscribe(
            companies => {

                this.companies = companies;
                this.selectedCompany = this.companies[0];

            });

    }

    registerUser({ value, valid }: { value: UserRegistration, valid: boolean }) {
        this.submitted = true;
        this.isRequesting = true;
        this.errors = '';
        if (valid) {
            this.userService.register(value.email, value.password, value.firstName, value.lastName, value.location,value.company, value.employeeNumber, value.cellphoneNumber, value.uID)
                .subscribe(

                    result => {
                        debugger;
                        if (result) {
                            this.router.navigate(['/login'], { queryParams: { brandNew: true, email: value.email } });
                        }
                    },
                    errors => this.errors = errors,
                    () => this.isRequesting = false);
        }
    }

    // Choose company using select dropdown
    changeCompany(companyId) {

        if (companyId > 0 && this.companies !== undefined) {
            this.selectedCompany = null;
            for (var i = 0; i < this.companies.length; i++) {
                if (this.companies[i].id == companyId) {
                    this.selectedCompany = this.companies[i];
                }
            }

        }

    }

}
