import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDetailComponent } from './product-detail.component';
import { ProductEditComponent } from './product-edit.component';
import { ProductComponent } from './product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { RouterModule } from '@angular/router';
import { ProductEditGuard } from './product-edit.guard';
import { MaterialModule } from 'src/app/material.module';
import { PlantVarietyEditComponent } from './plant-variety-edit/plant-variety-edit.component';




@NgModule({
  imports: [
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        DataTablesModule,
        MaterialModule,
        RouterModule.forChild([
            { path: 'products', component: ProductComponent },
            { path: 'products/:id', component: ProductDetailComponent },
            {
                path: 'products/:id/edit',
                canDeactivate: [ProductEditGuard],
                component: ProductEditComponent
            }
        ])
    ],
    declarations: [
        ProductDetailComponent, ProductEditComponent, ProductComponent, PlantVarietyEditComponent
    ],
    entryComponents: [PlantVarietyEditComponent]
})

export class ProductModule { }
