import { Component, OnInit } from '@angular/core';
import { Vehicle } from '../../../data-models/vehicle';
import { VehicleService } from '../../services/vehicle.service';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {

    pageTitle = 'Vehicle List';
    imageWidth = 50;
    imageMargin = 2;
    showImage = false;
    errorMessage = '';

    _listFilter = '';
    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredVehicles = this.listFilter ? this.performFilter(this.listFilter) : this.vehicles;
    }

    filteredVehicles: Vehicle[] = [];
    vehicles: Vehicle[] = [];
    //dtOptions: DataTables.Settings = {};
    dtOptions: any = {};
    constructor(private vehicleService: VehicleService) { }

    performFilter(filterBy: string): Vehicle[] {

        filterBy = filterBy.toLocaleLowerCase();
        return this.vehicles.filter((vehicle: Vehicle) =>
            vehicle.vehicleModel.toLocaleLowerCase().indexOf(filterBy) !== -1
            
        );
    }

    toggleImage(): void {
        this.showImage = !this.showImage;
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            dom: 'Bfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'print'
            ]
        };
        this.vehicleService.getVehicles().subscribe({
            next: item => {

                this.vehicles = item;
                this.filteredVehicles = this.vehicles;

            },
            error: err => this.errorMessage = err
        });
    }
}
