import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Vehicle } from '../../../data-models/vehicle';
import { VehicleMake } from '../../../data-models/vehiclemake';
import { VehicleModel } from '../../../data-models/vehiclemodel';
import { EmployeeService } from '../../services/employee.service';
import { VehicleMakeService } from '../../services/vehicle-make.service';
import { VehicleModelService } from '../../services/vehicle-model.service';
import { VehicleService } from '../../services/vehicle.service';

@Component({
  selector: 'app-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.css']
})
export class VehicleDetailComponent implements OnInit {
    pageTitle = 'Employee Detail';
    errorMessage = '';
    vehicle: Vehicle | undefined;
    vehicleMake: VehicleMake | undefined;
    vehicleModel: VehicleModel | undefined;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private vehicleService: VehicleService,
        private vehicleMakeService: VehicleMakeService,
        private vehicleModelService: VehicleModelService    ) {
    }

    ngOnInit() {
        const param = this.route.snapshot.paramMap.get('id');
        if (param) {
            const id = +param;
            this.getVehicle(id);

        }
    }

    getVehicle(id: number) {
        this.vehicleService.getVehicle(id).subscribe({
            next: model => {
                debugger;
                this.vehicle = model;
                this.getVehicleModel(model.modelId);
            },
            error: err => this.errorMessage = err
        });
    }
    getVehicleModel(id: number) {
        this.vehicleModelService.getVehicleModel(id).subscribe({
            next: vehicleModel => {
                this.vehicleModel = this.vehicleModel;
                this.vehicle.vehicleModel = vehicleModel.name;
                this.getVehicleMake(+vehicleModel.makeId);
            },
            error: err => this.errorMessage = err
        });
    }
    getVehicleMake(id: number) {
        this.vehicleMakeService.getVehicleMake(id).subscribe({
            next: vehicleMake => {
                this.vehicleMake = this.vehicleMake;
                this.vehicle.vehicleMake = vehicleMake.name;
                
            },
            error: err => this.errorMessage = err
        });
    }
    onBack(): void {
        this.router.navigate(['/dashboard/vehicle']);
    }

}
