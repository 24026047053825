import { Component, OnInit, ViewChildren, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName, FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Field } from '../../../data-models/field';
import { Subscription, Observable, fromEvent, merge } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FieldService } from '../../services/field.service';
import { GenericValidator } from '../../../shared/generic-validator';
import { debounceTime } from 'rxjs/operators';
import { debug } from 'util';
import { Farm } from '../../../data-models/farm';
import { FarmService } from '../../services/farm.service';
import { RentalAgreement } from '../../../data-models/rentalAgreement';
import { RentalAgreementService } from '../../../shared/services/rentalAgreement.service';

@Component({
    selector: 'app-field-edit',
    templateUrl: './field-edit.component.html',
    styleUrls: ['./field-edit.component.css']
})
export class FieldEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    pageTitle = 'Field Edit';
    errorMessage: string;
    fieldForm: FormGroup;

    field: Field;
    farms: Farm[] = [];
    rentalAgreements: RentalAgreement[];
    selectedFarm: Farm;
    selectedRentalAgreement: RentalAgreement;
    private sub: Subscription;

    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;

    get tags(): FormArray {
        return this.fieldForm.get('tags') as FormArray;
    }

    constructor(private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private fieldService: FieldService,
        private farmService: FarmService,
        private rentalAgreementService: RentalAgreementService) {

        // Defines all of the validation messages for the form.
        // These could instead be retrieved from a file or database.
        this.validationMessages = {
            fieldName: {
                required: 'Field name is required.',
                minlength: 'Field name must be at least three characters.',
                maxlength: 'Field name cannot exceed 100 characters.'
            },
            code: {
                required: 'Field code is required.'
            },
            farmId: {
                required: 'You must select a farm'
            }
        };
        // Define an instance of the validator for use with this form,
        // passing in this form's set of validation messages.
        this.genericValidator = new GenericValidator(this.validationMessages);
    }

    ngOnInit(): void {
        this.fieldForm = this.fb.group({
            fieldName: ['', [Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100)]],
            code: ['', Validators.required],
            farmId: ['', Validators.required],
            rentalAgreementId: '',
            size: 0,
            rowSpacing: '',
            farmName: '',
            rentalAgreementText: '',
            rentalAgreementName: '',
            id: 0
        });

        // Read the Field Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
            params => {
                // Get a List of farms
                this.farmService.getFarms().subscribe(
                    farms => {

                        this.farms = farms;
                        this.selectedFarm = this.farms[0];

                    });
                // Get a List of Rental Agreements
                this.rentalAgreementService.getRentalAgreements().subscribe(
                    rentalAgreements => {

                        this.rentalAgreements = rentalAgreements;
                        this.selectedRentalAgreement = this.rentalAgreements[0];

                    });

                const id = +params.get('id');
                this.getField(id);

            }
        );

    }


    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    ngAfterViewInit(): void {
        // Watch for the blur event from any input element on the form.
        // This is required because the valueChanges does not provide notification on blur
        const controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

        // Merge the blur event observable with the valueChanges observable
        // so we only need to subscribe once. 
        merge(this.fieldForm.valueChanges, ...controlBlurs).pipe(
            debounceTime(800)
        ).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.fieldForm);
        });
    }

    addTag(): void {
        this.tags.push(new FormControl());
    }

    deleteTag(index: number): void {
        this.tags.removeAt(index);
        this.tags.markAsDirty();
    }

    getField(id: number): void {
        if (id > 0) {
            this.fieldService.getField(id)
                .subscribe({
                    next: (field: Field) => {
                        this.displayField(field);

                        this.getRentalAgreementService(field.rentalAgreementId);
                        this.changeFarm(field.farmId);
                    },
                    error: err => this.errorMessage = err
                });
        }
    }
    getRentalAgreementService(rentalAgreementId: number) {
        this.rentalAgreementService.getRentalAgreement(rentalAgreementId)
            .subscribe({
                next: (rentalAgreement: RentalAgreement) => this.selectedRentalAgreement = rentalAgreement,
                error: err => this.errorMessage = err
            });
    }
    displayField(field: Field): void {
        if (this.fieldForm) {
            this.fieldForm.reset();
        }
        this.field = field;

        if (this.field.id === 0) {
            this.pageTitle = 'Add Field';
        } else {
            this.pageTitle = `Edit Field: ${this.field.fieldName}`;
        }

        // Update the data on the form
        this.fieldForm.patchValue({
            farmName: this.field.farmName,
            fieldName: this.field.fieldName,
            code: this.field.code,
            size: this.field.size,
            rentalAgreementId: this.field.rentalAgreementId,
            farmId: this.field.farmId,
            rowSpacing: this.field.rowSpacing,
            rentalAgreementText: field.rentalAgreementText,
            rentalAgreementName: field.rentalAgreementName,
            id: field.id
        });
        //this.farmForm.setControl('tags', this.fb.array(this.farm.tags || []));
    }

    deleteField(): void {
        if (this.field.id === 0) {
            // Don't delete, it was never saved.
            this.onSaveComplete();
        } else {
            if (confirm('Really delete the field: ${this.field.fieldName}?')) {
                this.fieldService.deleteField(this.field.id)
                    .subscribe({
                        next: () => this.onSaveComplete(),
                        error: err => this.errorMessage = err
                    });
            }
        }
    }

    saveField(): void {
        if (this.fieldForm.valid) {
            if (this.fieldForm.dirty) {
                const p = { ...this.field, ...this.fieldForm.value };

                if (p.id === 0) {
                    this.fieldService.createField(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                } else {
                    this.fieldService.updateField(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                }
            } else {
                this.onSaveComplete();
            }
        } else {
            this.errorMessage = 'Please correct the validation errors.';
        }
    }
    // Choose farm using select dropdown
    changeFarm(farmId) {
        this.selectedFarm = null;
        for (var i = 0; i < this.farms.length; i++) {
            if (this.farms[i].id == farmId) {
                this.selectedFarm = this.farms[i];
            }
        }
        //this.fieldForm.get("farm").setValue(this.selectedFarm, {
        //  onlySelf: true
        //});
        this.fieldForm.get("farmId").setValue(farmId, {
            onlySelf: true
        });
        this.fieldForm.get("farmName").setValue(this.selectedFarm.farmName, {
            onlySelf: true
        });

    }
    // Choose rental Agreement using select dropdown
    changeRentalAgreement(rentalAgreementId) {

        this.selectedRentalAgreement = null;
        for (var i = 0; i < this.rentalAgreements.length; i++) {
            if (this.rentalAgreements[i].id == rentalAgreementId) {
                this.selectedRentalAgreement = this.rentalAgreements[i];
            }
        }
        //this.fieldForm.get("farm").setValue(this.selectedFarm, {
        //  onlySelf: true
        //});
        this.fieldForm.get("rentalAgreementId").setValue(rentalAgreementId, {
            onlySelf: true
        });

    }
    onSaveComplete(): void {
        // Reset the form to clear the flags
        this.fieldForm.reset();
        this.router.navigate(['/dashboard/field']);
    }
}
