import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldEditComponent } from './field-edit.component';
import { FieldDetailComponent } from './field-detail.component';
import { FieldComponent } from './field.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/modules/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FieldEditGuard } from './field-edit.guard';
import { DataTablesModule } from 'angular-datatables';


@NgModule({
  declarations: [FieldEditComponent, FieldDetailComponent, FieldComponent],
  imports: [
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    DataTablesModule,
    RouterModule.forChild([
      { path: 'fields', component: FieldComponent },
      { path: 'fields/:id', component: FieldDetailComponent },
      {
        path: 'fields/:id/edit',
        canDeactivate: [FieldEditGuard],
        component: FieldEditComponent
      }
    ])
  ]
})
export class FieldModule { }
