import { Component, OnInit } from '@angular/core';
import { Trace } from 'src/app/data-models/trace';
import { TraceService } from '../../services/trace.service';

@Component({
  selector: 'app-trace',
  templateUrl: './trace.component.html',
  styleUrls: ['./trace.component.scss']
})
export class TraceComponent implements OnInit {
  pageTitle = 'Trace List';
  errorMessage = '';

  
  filteredTraces: Trace[] = [];
  traces: Trace[] = [];
  dtOptions: DataTables.Settings = {};


  constructor(private traceService: TraceService) {

  }

  
  
  ngOnInit(): void {
    //this.dataTable = $(this.table.nativeElement);

    //this.dataTable.DataTable();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };

    this.traceService.getTraces().subscribe({
      next: traces => {

        this.traces = traces;
        this.filteredTraces = this.traces;
      },
      error: err => this.errorMessage = err
    });
  }

}
