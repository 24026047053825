import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Field } from '../../../data-models/field';
import { FieldService } from '../../services/field.service';
import { debug } from 'util';

declare var $: any;

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit {
  pageTitle = 'Field List';
  imageWidth = 50;
  imageMargin = 2;
  showImage = false;
  errorMessage = '';
  
  _listFilter = '';
  get listFilter(): string {
    return this._listFilter;
  }
  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredFields = this.listFilter ? this.performFilter(this.listFilter) : this.fields;
  }

  filteredFields: Field[] = [];
  fields: Field[] = [];
  dtOptions: DataTables.Settings = {};
  

  constructor(private fieldService: FieldService) {

  }

  performFilter(filterBy: string): Field[] {

    filterBy = filterBy.toLocaleLowerCase();
    return this.fields.filter((field: Field) =>
      field.fieldName.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  toggleImage(): void {
    this.showImage = !this.showImage;
  }

  ngOnInit(): void {
    //this.dataTable = $(this.table.nativeElement);

    //this.dataTable.DataTable();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };

    this.fieldService.getFields().subscribe({
      next: fields => {

        this.fields = fields;
        this.filteredFields = this.fields;
      },
      error: err => this.errorMessage = err
    });
  }

}
