import { Injectable } from '@angular/core';
import { Employee } from 'src/app/data-models/employee';
import { throwError, Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {
    private employeeUrl = 'api/Employee';

    constructor(private http: HttpClient,) { }
    getEmployees(): Observable<Employee[]> {
        return this.http.get<Employee[]>(this.employeeUrl + '/GetEmployees')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    getEmployee(id: number): Observable<Employee> {
        if (id === 0) {
            return of(this.initializeEmployee());
        }
        const url = `${this.employeeUrl}/${id}`;

        return this.http.get<Employee>(url)
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    createEmployee(employee: Employee): Observable<Employee> {
        debugger;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        employee.id = null;
        return this.http.post<Employee>(this.employeeUrl + '/SaveEmployee', JSON.stringify(employee), { headers })
            .pipe(
                tap(data => console.log('createEmployee: ' + JSON.stringify(data))),
                catchError(this.handleError)
            );
    }

    deleteEmployee(id: number): Observable<{}> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.employeeUrl}/${id}`;
        return this.http.delete<Employee>(url, { headers })
            .pipe(
                tap(data => console.log('deleteEmployee: ' + id)),
                catchError(this.handleError)
            );
    }

    updateEmployee(employee: Employee): Observable<Employee> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.employeeUrl}/${employee.id}`;
        return this.http.put<Employee>(url, employee, { headers })
            .pipe(
                tap(() => console.log('updateEmployee: ' + employee.id)),
                // Return the field on an update
                map(() => employee),
                catchError(this.handleError)
            );
    }

    private handleError(err) {
        debugger;
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }

    private initializeEmployee(): Employee {
        // Return an initialized object
        return {
            id: 0,
            firstName: null,
            surname: null,
            genderId: "1",
            companyId: null,
            dateOfBirth: null,
            employeeNumber: null,
            companyName: null,
            jobTitle: null,
            gender:null
            

        };
    }
}

