import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChildren, AfterViewInit, OnDestroy, ElementRef, EventEmitter } from '@angular/core';
import { FormControlName, FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { Vehicle } from '../../../data-models/vehicle';
import { GenericValidator } from '../../../shared/generic-validator';
import { VehicleService } from '../../services/vehicle.service';
import { debounceTime } from 'rxjs/operators';
import { Employee } from '../../../data-models/employee';
import { Product } from '../../../data-models/product';
import { EmployeeService } from '../../services/employee.service';
import { ProductService } from '../../services/product.service';
import { EmployeeTrip } from '../../../data-models/employeetrip';
import { EmployeeTripService } from '../../services/employeetrip.service';
import { FileUploadServiceService } from '../../services/file-upload-service.service';
import { Output } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { debug } from 'util';
import { ValidateOdometerReadingValidator } from '../../../shared/modules/validate-odometero-readingdirective';

@Component({
    selector: 'app-employeetrip-edit',
    templateUrl: './employeetrip-edit.component.html',
    styleUrls: ['./employeetrip-edit.component.css']
})
export class EmployeetripEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    pageTitle = 'Trip Edit';
    shortLink: string = "";
    loading: boolean = false; // Flag variable
    errorMessage: string;
    messageInvoice: string;
    messageOdometer: string;
    employeeTripForm: FormGroup;
    employees: Employee[];
    products: Product[];
    vehicles: Vehicle[];
    employeeTrip: EmployeeTrip;
    selectedVehicle: Vehicle;
    selectedEmployee: Employee;
    selectedProduct: Product;
    private sub: Subscription;
    productType: number = 1008; //Fuel
    public response: { dbPath: '' };
    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;
    public progress: number;
    previousOdoReading: string;
    @Output() public onUploadFinished = new EventEmitter();

    constructor(private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private employeeTripService: EmployeeTripService,
        private vehicleService: VehicleService,
        private productService: ProductService,
        private employeeService: EmployeeService,
        public datepipe: DatePipe,
        private fileUploadService: FileUploadServiceService
    ) {

        // Defines all of the validation messages for the form.
        // These could instead be retrieved from a file or database.
        this.validationMessages = {

            employeeId: {
                required: 'Employee is required.'
            },

            vehicleId: {
                required: 'Vehicle is required.'
            },
            price: {
                required: 'Price/Litre is required.'
            },
            quantity: {
                required: 'Litre pumped is required.'
            },
            transactionDate: {
                required: 'Transaction Date is required.'
            },
            distanceTravelled: {
                required: 'distnace travelled is required.'
            },
            productId: {
                required: 'product purchased is required.'
            },
            odometerUrl: {
                required: 'Odometer reading picture is required.'
            }
        };

        // Define an instance of the validator for use with this form,
        // passing in this form's set of validation messages.
        this.genericValidator = new GenericValidator(this.validationMessages);

    }

    ngOnInit(): void {
        this.employeeTripForm = this.fb.group({
            employeeId: ['', Validators.required],
            vehicleId: ['', Validators.required],
            productId: ['', Validators.required],
            quantity: ['', Validators.required],
            price: ['', Validators.required],
            invoiceUrl: '',
            odometerUrl: '',
            previousReading: '',
            distanceTravelled: ['', Validators.required],
            transactionDate: ['', Validators.required],
            id: 0,
            employeeName: '',
            vehicleName: '',
            registrationNumber: '',
            productName: '',
            value: '',
            kmTravelled: ''
        }, { validators: ValidateOdometerReadingValidator });
        
        // Read the employee Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
            params => {
                // Get a List of Companies
                
                this.vehicleService.getVehicles().subscribe(
                    models => {

                        this.vehicles = models;
                        this.selectedVehicle = this.vehicles[0];

                    });
                this.employeeService.getEmployees().subscribe(
                    models => {

                        this.employees = models;
                        this.selectedEmployee = this.employees[0];

                    });
                this.productService.getProductsByProductType(this.productType).subscribe(
                    models => {

                        this.products = models;
                        this.selectedProduct = this.products[0];

                    });

                const id = +params.get('id');
                
                this.getEmployeeTrip(id);
            }
        );
        

    }
    get distanceTravelled() { return this.employeeTripForm.get('distanceTravelled'); }
    

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    ngAfterViewInit(): void {
        // Watch for the blur event from any input element on the form.
        // This is required because the valueChanges does not provide notification on blur
        const controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

        // Merge the blur event observable with the valueChanges observable
        // so we only need to subscribe once.
        merge(this.employeeTripForm.valueChanges, ...controlBlurs).pipe(
            debounceTime(800)
        ).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.employeeTripForm);
        });
    }


    getEmployeeTrip(id: number): void {
        this.employeeTripService.getEmployeeTrip(id)
            .subscribe({
                next: (employeeTrip: EmployeeTrip) => {

                    this.displayEmployeeTrip(employeeTrip);
                    debugger;
                    if (+employeeTrip.employeeId > 0) {
                        this.getEmployee(+employeeTrip.employeeId);
                        this.getVehicle(+employeeTrip.vehicleId);
                        this.getProduct(+employeeTrip.productId);


                    }

                },
                error: err => this.errorMessage = err
            });
    }

    getEmployee(id: number) {
        if (id > 0) {

            this.employeeService.getEmployee(id)
                .subscribe({
                    next: (employee: Employee) => this.selectedEmployee = employee,
                    error: err => this.errorMessage = err
                });
        }
    }
    getVehicle(id: number) {
        if (id > 0) {

            this.vehicleService.getVehicle(id)
                .subscribe({
                    next: (vehicle: Vehicle) => this.selectedVehicle = vehicle,
                    error: err => this.errorMessage = err
                });
        }
    }
    getProduct(id: number) {
        if (id > 0) {

            this.productService.getProduct(id)
                .subscribe({

                    next: (product: Product) => { this.selectedProduct = product; },
                    error: err => this.errorMessage = err
                });
        }
    }
    displayEmployeeTrip(employeeTrip: EmployeeTrip): void {
        if (this.employeeTripForm) {
            this.employeeTripForm.reset();
        }
        this.employeeTrip = employeeTrip;

        if (this.employeeTrip.id === 0) {
            this.pageTitle = 'Add Trip';
        } else {
            this.pageTitle = `Edit Trip: ${this.employeeTrip.employeeName} ${this.employeeTrip.vehicleName}`;
        }
        
        //this.previousOdoReading = this.selectedVehicle === undefined? "0" : this.selectedVehicle.ordometerReading;
        // Update the data on the form
        
        this.employeeTripForm.patchValue({
            id: this.employeeTrip.id,
            vehileName: this.employeeTrip.vehicleName,
            distanceTravelled: this.employeeTrip.distanceTravelled,
            employeeName: this.employeeTrip.employeeName,
            productName: this.employeeTrip.productName,
            productId: this.employeeTrip.productId,
            employeeId: this.employeeTrip.employeeId,
            vehicleId: this.employeeTrip.vehicleId,
            vehicleRegistrationNumber: this.employeeTrip.vehicleRegistrationNumber,
            price: this.employeeTrip.price,
            quantity: this.employeeTrip.quantity,
            transactionDate: this.datepipe.transform(this.employeeTrip.transactionDate, 'yyyy-MM-dd'),
            invoiceUrl: this.employeeTrip.invoiceUrl,
            odometerUrl: this.employeeTrip.odometerUrl,
            previousReading: this.previousOdoReading,
            companyName: this.employeeTrip.companyName
            
            
        });
        this.messageInvoice = this.employeeTrip.invoiceUrl;
        this.messageOdometer = this.employeeTrip.odometerUrl;
    }

    deleteEmployeeTrip(): void {
        if (this.employeeTrip.id === 0) {
            // Don't delete, it was never saved.
            this.onSaveComplete();
        } else {
            if (confirm(`Really delete the trip: ${this.employeeTrip.employeeName} ${this.employeeTrip.vehicleName}?`)) {
                this.employeeTripService.deleteEmployeeTrip(this.employeeTrip.id)
                    .subscribe({
                        next: () => this.onSaveComplete(),
                        error: err => this.errorMessage = err
                    });
            }
        }
    }

    saveEmployeeTrip(): void {
        
        if (this.employeeTripForm.valid) {
            if (this.employeeTripForm.dirty) {
                const p = { ...this.employeeTrip, ...this.employeeTripForm.value };

                this.loading = !this.loading;
                console.log(p.invoiceUrl);
                if (p.id === 0 || p.id === null) {
            
                    this.employeeTripService.createEmployeeTrip(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                } else {

                    if (p.employeeId === undefined) {
                        p.employeeId = this.selectedEmployee.id;
                        p.employeeName = this.selectedEmployee.firstName + " " + this.selectedEmployee.surname;
                    }
                    if (p.invoiceUrl === undefined) { p.invoiceUrl = this.messageInvoice; }
                    if (p.odometerUrl === undefined) { p.odometerUrl = this.messageOdometer; }
                    this.employeeTripService.updateEmployeeTrip(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                }
            } else {
                this.onSaveComplete();
            }
        } else {
            this.errorMessage = 'Please correct the validation errors.';
        }
    }

    onSaveComplete(): void {

        // Reset the form to clear the flags

        this.employeeTripForm.reset();
        this.router.navigate(['/dashboard/employeetrip']);
    }
    // On file Select
    onInvoiceChange(event) {
        
        if (this.employeeTripForm !== null) {
            
            
            //this.fileUploadService.uploadFile(this.employeeTripForm.get("invoiceUrl").value);
            this.fileUploadService.upload(event.target.files[0]).subscribe(
                event => {

                    if (event.type === HttpEventType.UploadProgress)
                        console.log(Math.round(100 * event.loaded / event.total));
                    else if (event.type === HttpEventType.Response) {
                        console.log('Upload success.');
                        this.onUploadFinished.emit(event.body);
                        this.messageInvoice = "File Uploaded";
                        this.displayMessage.invoiceUrl == "File Uploaded";
                        this.employeeTripForm.get("invoiceUrl").setValue(event.body.dbPath);
                    }
                });
            this.employeeTripForm.get("invoiceUrl").setValue(event.target.files[0], {
                onlySelf: true
            });
        }

    }
    onOdometerChange(event) {
        if (this.employeeTripForm !== null) {
               
            this.fileUploadService.upload(event.target.files[0]).subscribe(
                event => {

                    if (event.type === HttpEventType.UploadProgress)
                        console.log(Math.round(100 * event.loaded / event.total));
                    else if (event.type === HttpEventType.Response) {
                        console.log('Upload success.');
                        this.onUploadFinished.emit(event.body);
                        this.messageOdometer = "File Uploaded";
                        this.displayMessage.odometerUrl == "File Uploaded";
                        this.employeeTripForm.get("odometerUrl").setValue(event.body.dbPath);
                    }
                });

            this.employeeTripForm.get("odometerUrl").setValue(event.target.files[0], {
                onlySelf: true
            });

        }

    }
    changeVehicle(id: number) {
        if (id > 0 && this.vehicles !== undefined) {
            this.selectedVehicle = null;
            for (var i = 0; i < this.vehicles.length; i++) {
                if (this.vehicles[i].id == id) {
                    this.selectedVehicle = this.vehicles[i];
                }
            }

            if (this.employeeTripForm !== null) {
                this.employeeTripForm.get("vehicleId").setValue(id, {
                    onlySelf: true
                });
            }
            if (this.employeeTripForm !== null) {
                this.employeeTripForm.get("previousReading").setValue(this.selectedVehicle.ordometerReading, {
                    onlySelf: true
                });
                this.previousOdoReading = this.selectedVehicle.ordometerReading;
            }
            debugger;
        }

    }
    changeEmployee(id: number) {
        if (id > 0 && this.employees !== undefined) {
            this.selectedEmployee = null;
            for (var i = 0; i < this.employees.length; i++) {
                if (this.employees[i].id == id) {
                    this.selectedEmployee = this.employees[i];
                }
            }

            if (this.employeeTripForm !== null) {
                this.employeeTripForm.get("employeeId").setValue(id, {
                    onlySelf: true
                });
            }
        }
    }
    changeProduct(id: number) {

        if (id > 0 && this.products !== undefined) {
            this.selectedProduct = null;
            for (var i = 0; i < this.products.length; i++) {
                if (this.products[i].id == id) {
                    this.selectedProduct = this.products[i];
                }
            }

            if (this.employeeTripForm !== null) {
                this.employeeTripForm.get("productId").setValue(id, {
                    onlySelf: true
                });
            }
        }
    }
    public uploadFinished = (event) => {
        
        this.response = event;
    }
}





