import { Component, OnInit } from '@angular/core';
import { Farm } from '../../../data-models/farm';
import { FarmService } from '../../services/farm.service';

@Component({
  selector: 'app-farm',
  templateUrl: './farm.component.html',
  styleUrls: ['./farm.component.scss']
})
export class FarmComponent implements OnInit {

  pageTitle = 'Farm List';
  imageWidth = 50;
  imageMargin = 2;
  showImage = false;
  errorMessage = '';

  _listFilter = '';
  get listFilter(): string {
    return this._listFilter;
  }
  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredFarms = this.listFilter ? this.performFilter(this.listFilter) : this.farms;
  }

  filteredFarms: Farm[] = [];
  farms: Farm[] = [];
  dtOptions: DataTables.Settings = {};

  constructor(private farmService: FarmService) { }

  performFilter(filterBy: string): Farm[] {
    
    filterBy = filterBy.toLocaleLowerCase();
    return this.farms.filter((farm: Farm) =>
      farm.farmName.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  toggleImage(): void {
    this.showImage = !this.showImage;
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    this.farmService.getFarms().subscribe({
        next: farms => {
        this.farms = farms;
        this.filteredFarms = this.farms;
            debugger;
      },
      error: err => this.errorMessage = err
    });
  }
}
