import { Component,ElementRef, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Farm Management App';

  ngOnInit(): void {
    
    $.fn.dataTable.ext.errMode = 'none';
  }
}
