import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

import { ConfigService } from '../../shared/utils/config.service';

import {BaseService} from '../../shared/services/base.service';

import { Observable, pipe } from 'rxjs'; 

// Add the RxJS Observable operators we need in this app.
//import '../../rxjs-operators';
import { Response } from 'selenium-webdriver/http';
import { map, catchError } from 'rxjs/operators';
import { Home } from 'src/app/data-models/home';
import { EmployeeTrip } from '../../data-models/employeetrip';

@Injectable()

export class DashboardService extends BaseService {

  baseUrl: string = ''; 

  constructor(private http: HttpClient, private configService: ConfigService) {
     super();
     this.baseUrl = configService.getApiURI();
  }

  getHomeDetails(): Observable<Home> {

      //let headers = new HttpHeaders();
      //headers.append('Content-Type', 'application/json');
      let authToken = localStorage.getItem('auth_token');
      //headers.append('Authorization', `Bearer ${authToken}`);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      })
    };
    //httpOptions.headers =
    //  httpOptions.headers.set('Authorization',  `Bearer ${authToken}`);
    return this.http.get(this.baseUrl + "/dashboard/home",httpOptions)
     .pipe(map((response: Response) => ((<any>response)) as Home),catchError(this.handleError));
    }

    getTripHomeDetails(): Observable<EmployeeTrip[]> {

        //let headers = new HttpHeaders();
        //headers.append('Content-Type', 'application/json');
        let authToken = localStorage.getItem('auth_token');
        //headers.append('Authorization', `Bearer ${authToken}`);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            })
        };
        //httpOptions.headers =
        //  httpOptions.headers.set('Authorization',  `Bearer ${authToken}`);
        return this.http.get(this.baseUrl + "/dashboard/home", httpOptions)
            .pipe(map((response: Response) => ((<any>response)) as EmployeeTrip[]), catchError(this.handleError));
    }
}
