import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { VehicleMakeEditComponent } from './vehicle-make-edit.component';

@Injectable({
  providedIn: 'root'
})
export class VehicleMakeEditGuard implements CanDeactivate<VehicleMakeEditComponent> {
    canDeactivate(component: VehicleMakeEditComponent): Observable<boolean> | Promise<boolean> | boolean {
        if (component.vehicleMakeForm.dirty) {
            const vehicleMake = component.vehicleMakeForm.get('make').value || 'New Vehicle Make';
            return confirm(`Navigate away and lose all changes to ${vehicleMake}?`);
        }
        return true;
    }
}