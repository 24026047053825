import { Component, OnInit } from '@angular/core';
import { VehicleMake} from '../../../data-models/vehiclemake';
import { VehicleMakeService } from '../../services/vehicle-make.service';

@Component({
  selector: 'app-vehicle-make',
  templateUrl: './vehicle-make.component.html',
  styleUrls: ['./vehicle-make.component.css']
})
export class VehicleMakeComponent implements OnInit {

    pageTitle = 'Vehicle List';
    imageWidth = 50;
    imageMargin = 2;
    showImage = false;
    errorMessage = '';

    _listFilter = '';
    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredVehicleMakes = this.listFilter ? this.performFilter(this.listFilter) : this.vehicleMakes;
    }

    filteredVehicleMakes: VehicleMake[] = [];
    vehicleMakes: VehicleMake[] = [];
    //dtOptions: DataTables.Settings = {};
    dtOptions: any = {};
    constructor(private vehicleMakeService: VehicleMakeService) { }

    performFilter(filterBy: string): VehicleMake[] {

        filterBy = filterBy.toLocaleLowerCase();
        return this.vehicleMakes.filter((vehicleMake: VehicleMake) =>
            vehicleMake.name.toLocaleLowerCase().indexOf(filterBy) !== -1

        );
    }

    toggleImage(): void {
        this.showImage = !this.showImage;
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            dom: 'Bfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'print'
            ]
        };
        this.vehicleMakeService.getVehicleMakes().subscribe({
            next: item => {

                this.vehicleMakes = item;
                this.filteredVehicleMakes = this.vehicleMakes;

            },
            error: err => this.errorMessage = err
        });
    }
}
