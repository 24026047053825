import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { FarmEditComponent } from './farm.edit.component';

@Injectable({
  providedIn: 'root'
})
export class FarmEditGuard implements  CanDeactivate<FarmEditComponent> {
  canDeactivate(component: FarmEditComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (component.farmForm.dirty) {
      const farmName = component.farmForm.get('farmName').value || 'New Farm';
      return confirm(`Navigate away and lose all changes to ${farmName}?`);
    }
    return true;
  }
}