import { Injectable } from '@angular/core';
import { throwError, Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { VehicleMake } from '../../data-models/vehiclemake';
import { VehicleModel } from '../../data-models/vehiclemodel';
import { debug } from 'util';

@Injectable({
  providedIn: 'root'
})
export class VehicleMakeService {
    private vehicleMakeUrl = 'api/VehicleMake';

    constructor(private http: HttpClient,) { }
    getVehicleMakes(): Observable<VehicleMake[]> {
        return this.http.get<VehicleMake[]>(this.vehicleMakeUrl + '/GetVehicleMakes')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }
    getVehicleModelsByMake(id: number): Observable<VehicleModel[]> {
        
        
        if (id === 0) {
            //return of(this.initializeVehicleMake());
        }
        return this.http.get<VehicleModel[]>(this.vehicleMakeUrl + '/' + id + '/GetVehicleModelsByMakeId')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }
    getVehicleMake(id: number): Observable<VehicleMake> {
        if (id === 0) {
            return of(this.initializeVehicleMake());
        }
        const url = `${this.vehicleMakeUrl}/${id}`;

        return this.http.get<VehicleMake>(url)
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    createVehicleMake(vehicleMake: VehicleMake): Observable<VehicleMake> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        vehicleMake.id = null;
        return this.http.post<VehicleMake>(this.vehicleMakeUrl + '/SaveVehicleMake', JSON.stringify(vehicleMake), { headers })
            .pipe(
                tap(data => console.log('createVehicleMake: ' + JSON.stringify(data))),
                catchError(this.handleError)
            );
    }

    deleteVehicleMake(id: number): Observable<{}> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.vehicleMakeUrl}/${id}`;
        return this.http.delete<VehicleMake>(url, { headers })
            .pipe(
                tap(data => console.log('deleteVehicleMake: ' + id)),
                catchError(this.handleError)
            );
    }

    updateVehicleMake(vehicleMake: VehicleMake): Observable<VehicleMake> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.vehicleMakeUrl}/${vehicleMake.id}`;
        return this.http.put<VehicleMake>(url, vehicleMake, { headers })
            .pipe(
                tap(() => console.log('updateVehicleMake: ' + vehicleMake.id)),
                // Return the field on an update
                map(() => vehicleMake),
                catchError(this.handleError)
            );
    }

    private handleError(err) {
        debugger;
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }

    private initializeVehicleMake(): VehicleMake {
        // Return an initialized object
        return {
            id: 0,
            name: null
        };
    }
}


