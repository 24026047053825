import { Component, OnInit } from '@angular/core';
import { Employee } from '../../../data-models/employee';
import { EmployeeService } from '../../services/employee.service';

@Component({
    selector: 'app-employee',
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

    pageTitle = 'Employee List';
    imageWidth = 50;
    imageMargin = 2;
    showImage = false;
    errorMessage = '';

    _listFilter = '';
    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredEmployees = this.listFilter ? this.performFilter(this.listFilter) : this.employees;
    }

    filteredEmployees: Employee[] = [];
    employees: Employee[] = [];
    //dtOptions: DataTables.Settings = {};
    dtOptions: any = {};
    constructor(private employeeService: EmployeeService) { }

    performFilter(filterBy: string): Employee[] {

        filterBy = filterBy.toLocaleLowerCase();
        return this.employees.filter((employee: Employee) => 
            employee.firstName.toLocaleLowerCase().indexOf(filterBy) !== -1 
            ||
            employee.surname.toLocaleLowerCase().indexOf(filterBy) !== -1 ||
            employee.employeeNumber.toLocaleLowerCase().indexOf(filterBy) !== -1
        );
    }

    toggleImage(): void {
        this.showImage = !this.showImage;
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true ,
            dom: 'Bfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'print'
            ]
        };

      

        this.employeeService.getEmployees().subscribe({
            next: item => {
                
                this.employees = item;
                this.filteredEmployees = this.employees;
                
            },
            error: err => this.errorMessage = err
        });
    }
}
