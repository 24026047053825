import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChildren, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName, FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { Vehicle } from '../../../data-models/vehicle';
import { VehicleMake } from '../../../data-models/vehiclemake';
import { VehicleModel } from '../../../data-models/vehiclemodel';
import { GenericValidator } from '../../../shared/generic-validator';
import { VehicleService } from '../../services/vehicle.service';
import { VehicleMakeService } from '../../services/vehicle-make.service';
import { VehicleModelService } from '../../services/vehicle-model.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-vehicle-edit',
  templateUrl: './vehicle-edit.component.html',
  styleUrls: ['./vehicle-edit.component.css']
})
export class VehicleEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    pageTitle = 'Vehicle Edit';
    errorMessage: string;
    vehicleForm: FormGroup;
    vehicleMakes: VehicleMake[];
    vehicleModels: VehicleModel[];
    vehicle: Vehicle;
    selectedVehicleMake: VehicleMake;
    selectedVehicleModel: VehicleModel;
    private sub: Subscription;

    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;

    

    constructor(private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private vehicleService: VehicleService,
        private vehicleModelService: VehicleModelService,
        private vehicleMakeService: VehicleMakeService,
        public datepipe: DatePipe
    ) {

        // Defines all of the validation messages for the form.
        // These could instead be retrieved from a file or database.
        this.validationMessages = {
           
            registrationNumber: {
                required: 'Registration number is required.'
            },
            
            modelId: {
                required: 'Vehicle model is required.'
            }
        };

        // Define an instance of the validator for use with this form,
        // passing in this form's set of validation messages.
        this.genericValidator = new GenericValidator(this.validationMessages);

    }

    ngOnInit(): void {
        this.vehicleForm = this.fb.group({
            registrationNumber: ['', Validators.required],
            ordometerReading: ['', Validators.required],
            vehicleMakeId: '',
            modelId: ['', Validators.required],
            VIN: '',
            previousReading: '',
            distanceTravelled: '',
            id: 0
        });
        
        // Read the employee Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
            params => {
                // Get a List of Companies
                this.vehicleMakeService.getVehicleMakes().subscribe(
                    models => {

                        this.vehicleMakes = models;
                        this.selectedVehicleMake = this.vehicleMakes[0];

                    });
                this.vehicleModelService.getVehicleModels().subscribe(
                    models => {

                        this.vehicleModels = models;
                        this.selectedVehicleModel = this.vehicleModels[0];

                    });

                const id = +params.get('id');
                this.getVehicle(id);
            }
        );
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    ngAfterViewInit(): void {
        // Watch for the blur event from any input element on the form.
        // This is required because the valueChanges does not provide notification on blur
        const controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

        // Merge the blur event observable with the valueChanges observable
        // so we only need to subscribe once.
        merge(this.vehicleForm.valueChanges, ...controlBlurs).pipe(
            debounceTime(800)
        ).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.vehicleForm);
        });
    }


    getVehicle(id: number): void {
        this.vehicleService.getVehicle(id)
            .subscribe({
                next: (vehicle: Vehicle) => {
                    this.displayVehicle(vehicle);
                    if (vehicle.modelId > 0) {
                        this.getVehicleModelService(vehicle.modelId);
                        this.changeVehicleModel(vehicle.modelId);

                    }

                },
                error: err => this.errorMessage = err
            });
    }
    getVehicleModels(makeid: number) {
        
        this.vehicleMakeService.getVehicleModelsByMake(makeid).subscribe(
            models => {

                this.vehicleModels = models;
                this.selectedVehicleModel = this.vehicleModels[0];

            });

    }
    getVehicleModelService(id: number) {
        if (id > 0) {

            this.vehicleModelService.getVehicleModel(id)
                .subscribe({
                    next: (vehicleModel: VehicleModel) => this.selectedVehicleModel = vehicleModel,
                    error: err => this.errorMessage = err
                });
        }
    }

    // Choose company using select dropdown
    changeVehicleModel(vehicleModelId) {

        if (vehicleModelId > 0 && this.vehicleModels !== undefined) {
            this.selectedVehicleModel = null;
            for (var i = 0; i < this.vehicleModels.length; i++) {
                if (this.vehicleModels[i].id == vehicleModelId) {
                    this.selectedVehicleModel = this.vehicleModels[i];
                }
            }

            if (this.vehicleForm !== null) {
                this.vehicleForm.get("modelId").setValue(vehicleModelId, {
                    onlySelf: true
                });
                //this.employeeForm.get("company").setValue(this.selectedCompany.name, {
                //    onlySelf: true
                //});
            }
        }

    }
    changeVehicleMake(id: number) {
        if (id > 0 && this.vehicleMakes !== undefined) {
            this.selectedVehicleMake = null;
            for (var i = 0; i < this.vehicleMakes.length; i++) {
                if (this.vehicleMakes[i].id == id) {
                    this.selectedVehicleMake = this.vehicleMakes[i];
                }
            }

            if (this.vehicleForm !== null) {
                this.vehicleForm.get("modelId").setValue(id, {
                    onlySelf: true
                });
                this.getVehicleModels(id);
                //this.employeeForm.get("company").setValue(this.selectedCompany.name, {
                //    onlySelf: true
                //});
            }
        }
    }
    displayVehicle(vehicle: Vehicle): void {
        if (this.vehicleForm) {
            this.vehicleForm.reset();
        }
        this.vehicle = vehicle;

        if (this.vehicle.id === 0) {
            this.pageTitle = 'Add Vehicle';
        } else {
            this.pageTitle = `Edit Vehicle: ${this.vehicle.vehicleMake} ${this.vehicle.vehicleModel}`;
        }
        debugger;
        // Update the data on the form
        this.vehicleForm.patchValue({
            vehileMake: this.vehicle.vehicleMake,
            distanceTravelled: this.vehicle.distanceTravelled,
            vehicleModel: this.vehicle.vehicleModel,
            previousReading: this.vehicle.previousReading,
            ordometerReading : this.vehicle.ordometerReading ,
            registrationNumber: this.vehicle.registrationNumber,
            VIN: this.vehicle.vin,
            modelId: this.vehicle.modelId,
            id: this.vehicle.id
        });

    }

    deleteVehicle(): void {
        if (this.vehicle.id === 0) {
            // Don't delete, it was never saved.
            this.onSaveComplete();
        } else {
            if (confirm(`Really delete the Vehicle: ${this.vehicle.vehicleMake} ${this.vehicle.vehicleModel}?`)) {
                this.vehicleService.deleteVehicle(this.vehicle.id)
                    .subscribe({
                        next: () => this.onSaveComplete(),
                        error: err => this.errorMessage = err
                    });
            }
        }
    }

    saveVehicle(): void {
        debugger;
        if (this.vehicleForm.valid) {
            if (this.vehicleForm.dirty) {
                const p = { ...this.vehicle, ...this.vehicleForm.value };

                if (p.id === 0 || p.id === null) {
                    this.vehicleService.createVehicle(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                } else {
                    if (p.modelId === undefined) {
                        p.modelId = this.selectedVehicleModel.id;
                        p.vehicleModel = this.selectedVehicleModel.name;
                    }
                    this.vehicleService.updateVehicle(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                }
            } else {
                this.onSaveComplete();
            }
        } else {
            this.errorMessage = 'Please correct the validation errors.';
        }
    }

    onSaveComplete(): void {
        // Reset the form to clear the flags
        this.vehicleForm.reset();
        this.router.navigate(['/dashboard/vehicle']);
    }
}

