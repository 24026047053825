import { Component, OnInit, ViewChildren, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName, FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Farm } from '../../../data-models/farm';
import { Subscription, Observable, fromEvent, merge } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FarmService } from '../../services/farm.service';
import { GenericValidator } from '../../../shared/generic-validator';
import { debounceTime } from 'rxjs/operators';
import { debug } from 'util';

@Component({
  selector: 'app-farm.edit',
  templateUrl: './farm.edit.component.html',
  styleUrls: ['./farm.edit.component.scss']
})
export class FarmEditComponent  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  pageTitle = 'Farm Edit';
  errorMessage: string;
  farmForm: FormGroup;

  farm: Farm;
  private sub: Subscription;

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  get tags(): FormArray {
    return this.farmForm.get('tags') as FormArray;
  }

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private farmService: FarmService) {

    // Defines all of the validation messages for the form.
    // These could instead be retrieved from a file or database.
    this.validationMessages = {
      farmName: {
        required: 'Farm name is required.',
        minlength: 'Farm name must be at least three characters.',
        maxlength: 'Farm name cannot exceed 100 characters.'
      },
      farmCode: {
        required: 'Farm code is required.'
      }
    };

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    this.farmForm = this.fb.group({
      farmName: ['', [Validators.required,
                         Validators.minLength(3),
                         Validators.maxLength(100)]],
      farmCode: ['', Validators.required],
      longitute: '',
      latitude: '',
      size: 0,
      
    });

    // Read the farm Id from the route parameter
    this.sub = this.route.paramMap.subscribe(
      params => {
        const id = +params.get('id');
        this.getFarm(id);
      }
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    // This is required because the valueChanges does not provide notification on blur
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    // so we only need to subscribe once.
    merge(this.farmForm.valueChanges, ...controlBlurs).pipe(
      debounceTime(800)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.farmForm);
    });
  }

  addTag(): void {
    this.tags.push(new FormControl());
  }

  deleteTag(index: number): void {
    this.tags.removeAt(index);
    this.tags.markAsDirty();
  }

  getFarm(id: number): void {
    this.farmService.getFarm(id)
      .subscribe({
        next: (farm: Farm) => this.displayFarm(farm),
        error: err => this.errorMessage = err
      });
  }

  displayFarm(farm: Farm): void {
    if (this.farmForm) {
      this.farmForm.reset();
    }
    this.farm = farm;

    if (this.farm.id === 0) {
      this.pageTitle = 'Add Farm';
    } else {
      this.pageTitle = 'Edit Farm: ${this.farm.farmName}';
    }

    // Update the data on the form
    this.farmForm.patchValue({
      farmName: this.farm.farmName,
      farmCode: this.farm.farmCode,
      size: this.farm.size,
      latitude: this.farm.latitude,
      longitute: this.farm.longitute
    });
    //this.farmForm.setControl('tags', this.fb.array(this.farm.tags || []));
  }

  deleteFarm(): void {
    if (this.farm.id === 0) {
      // Don't delete, it was never saved.
      this.onSaveComplete();
    } else {
      if (confirm('Really delete the farm: ${this.farm.farmName}?')) {
        this.farmService.deleteFarm(this.farm.id)
          .subscribe({
            next: () => this.onSaveComplete(),
            error: err => this.errorMessage = err
          });
      }
    }
  }

  saveFarm(): void {
    if (this.farmForm.valid) {
      if (this.farmForm.dirty) {
        const p = { ...this.farm, ...this.farmForm.value };

        if (p.id === 0) {
          this.farmService.createFarm(p)
            .subscribe({
              next: () => this.onSaveComplete(),
              error: err => this.errorMessage = err
            });
        } else {
          this.farmService.updateFarm(p)
            .subscribe({
              next: () => this.onSaveComplete(),
              error: err => this.errorMessage = err
            });
        }
      } else {
        this.onSaveComplete();
      }
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(): void {
    // Reset the form to clear the flags
    this.farmForm.reset();
    this.router.navigate(['/farms']);
  }
}
