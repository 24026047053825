import { Injectable } from '@angular/core';
import { EmployeeTrip } from 'src/app/data-models/employeetrip';
import { throwError, Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EmployeeTripService {
    private employeeTripUrl = 'api/EmployeeTrip';

    constructor(private http: HttpClient,) { }
    getEmployeeTrips(): Observable<EmployeeTrip[]> {
        return this.http.get<EmployeeTrip[]>(this.employeeTripUrl + '/GetEmployeeTrips')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }
    getEmployeeTripsByEmployeeId(id : number): Observable<EmployeeTrip[]> {
        return this.http.get<EmployeeTrip[]>(this.employeeTripUrl + '/' + id + '/getEmployeeTripsByEmployeeId')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
        );
        
    }
    getEmployeeTripsByVehicleId(id : number): Observable<EmployeeTrip[]> {
        
        return this.http.get<EmployeeTrip[]>(this.employeeTripUrl + '/' + id + '/GetEmployeeTripsByVehicleId')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    getEmployeeTrip(id: number): Observable<EmployeeTrip> {
        if (id === 0) {
            return of(this.initializeEmployeeTrip());
        }
        const url = `${this.employeeTripUrl}/${id}`;

        return this.http.get<EmployeeTrip>(url)
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    createEmployeeTrip(employeeTrip: EmployeeTrip): Observable<EmployeeTrip> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        employeeTrip.id = null;
        return this.http.post<EmployeeTrip>(this.employeeTripUrl + '/SaveEmployeeTrip', JSON.stringify(employeeTrip), { headers })
            .pipe(
                tap(data => console.log('createEmployeeTrip: ' + JSON.stringify(data))),
                catchError(this.handleError)
            );
    }

    deleteEmployeeTrip(id: number): Observable<{}> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.employeeTripUrl}/${id}`;
        return this.http.delete<EmployeeTrip>(url, { headers })
            .pipe(
                tap(data => console.log('deleteEmployeeTrip: ' + id)),
                catchError(this.handleError)
            );
    }

    updateEmployeeTrip(employeeTrip: EmployeeTrip): Observable<EmployeeTrip> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.employeeTripUrl}/${employeeTrip.id}`;
        return this.http.put<EmployeeTrip>(url, employeeTrip, { headers })
            .pipe(
                tap(() => console.log('updateEmployeeTrip: ' + employeeTrip.id)),
                // Return the field on an update
                map(() => employeeTrip),
                catchError(this.handleError)
            );
    }

    private handleError(err) {
        debugger;
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }

    private initializeEmployeeTrip(): EmployeeTrip{
        // Return an initialized object
        return {
            id: 0,
            employeeId: null,
            vehicleId: null,
            productId: null,
            distanceTravelled: null,
            price: null,
            quantity: null,
            transactionDate: null,
            invoiceUrl: null,
            employeeName: null,
            productName: null,
            vehicleName: null,
            vehicleRegistrationNumber: null,
            kmTravelled: null,
            value: null,
            companyName: null,
            valuePerkmTravelled: null,
            odometerUrl:null
        };
    }
}

