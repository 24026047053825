import { Injectable } from '@angular/core';
@Injectable()
export class ConfigService {
     
    _apiURI: string;
    _URI: string;
    constructor() {
        
        const parsedUrl = new URL(window.location.href);
        const baseUrl = parsedUrl.origin;
        console.log(baseUrl);
        this._apiURI = baseUrl + '/api';
        this._URI = baseUrl;
     }
 
     getApiURI() {
         return this._apiURI;
    }
    getBaseURI() {
        return this._URI;
    }
}
 