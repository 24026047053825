import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChildren, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName, FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { VehicleMake } from '../../../data-models/vehiclemake';
import { VehicleModel } from '../../../data-models/vehiclemodel';
import { GenericValidator } from '../../../shared/generic-validator';
import { VehicleMakeService } from '../../services/vehicle-make.service';
import { VehicleModelService } from '../../services/vehicle-model.service';

@Component({
  selector: 'app-vehicle-model-edit',
  templateUrl: './vehicle-model-edit.component.html',
  styleUrls: ['./vehicle-model-edit.component.css']
})
export class VehicleModelEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    pageTitle = 'Vehicle Model Edit';
    errorMessage: string;
    vehicleModelForm: FormGroup;
    vehicleMakes: VehicleMake[];
    vehicleModels: VehicleModel[];
    selectedVehicleMake: VehicleMake;
    vehicleModel: VehicleModel;
    private sub: Subscription;

    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;



    constructor(private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private vehicleModelService: VehicleModelService,
        private vehicleMakeService: VehicleMakeService,
        public datepipe: DatePipe
    ) {

        // Defines all of the validation messages for the form.
        // These could instead be retrieved from a file or database.
        this.validationMessages = {

            VehicleMake: {
                required: 'Vehicle Make is required.'
            },

            VehicleModel: {
                required: 'Vehicle model is required.'
            }
        };

        // Define an instance of the validator for use with this form,
        // passing in this form's set of validation messages.
        this.genericValidator = new GenericValidator(this.validationMessages);

    }

    ngOnInit(): void {
        this.vehicleModelForm = this.fb.group({
            makeId: ['', Validators.required],
            name: ['', Validators.required],
            id: 0
        });

        // Read the employee Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
            params => {
                // Get a List of Companies
                this.vehicleMakeService.getVehicleMakes().subscribe(
                    models => {

                        this.vehicleMakes = models;
                        this.selectedVehicleMake = this.vehicleMakes[0];

                    });
               
                const id = +params.get('id');
                this.getVehicleModel(id);
            }
        );
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    ngAfterViewInit(): void {
        // Watch for the blur event from any input element on the form.
        // This is required because the valueChanges does not provide notification on blur
        const controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

        // Merge the blur event observable with the valueChanges observable
        // so we only need to subscribe once.
        merge(this.vehicleModelForm.valueChanges, ...controlBlurs).pipe(
            debounceTime(800)
        ).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.vehicleModelForm);
        });
    }


    getVehicleModel(id: number): void {
        this.vehicleModelService.getVehicleModel(id)
            .subscribe({
                next: (vehicleModel: VehicleModel) => {
                    this.displayVehicleModel(vehicleModel);
                    if (+vehicleModel.makeId > 0) {
                        this.getVehicleMakeService(+vehicleModel.makeId);
                        this.changeVehicleMake(+vehicleModel.makeId);

                    }

                },
                error: err => this.errorMessage = err
            });
    }
    getVehicleModels(makeid: number) {

        this.vehicleMakeService.getVehicleModelsByMake(makeid).subscribe(
            models => {

                this.vehicleModels = models;
                
            });

    }
    getVehicleMakeService(id: number) {
        if (id > 0) {

            this.vehicleMakeService.getVehicleMake(id)
                .subscribe({
                    next: (vehicleMake: VehicleMake) => this.selectedVehicleMake = vehicleMake,
                    error: err => this.errorMessage = err
                });
        }
    }

    
    changeVehicleMake(id: number) {
        if (id > 0 && this.vehicleMakes !== undefined) {
            this.selectedVehicleMake = null;
            for (var i = 0; i < this.vehicleMakes.length; i++) {
                if (this.vehicleMakes[i].id == id) {
                    this.selectedVehicleMake = this.vehicleMakes[i];
                }
            }

            if (this.vehicleModelForm !== null) {
                this.vehicleModelForm.get("makeId").setValue(id, {
                    onlySelf: true
                });
               
            }
        }
    }
    displayVehicleModel(vehicleModel: VehicleModel): void {
        if (this.vehicleModelForm) {
            this.vehicleModelForm.reset();
        }
        this.vehicleModel = vehicleModel;

        if (this.vehicleModel.id === 0) {
            this.pageTitle = 'Add Vehicle Model';
        } else {
            this.pageTitle = `Edit Vehicle Model: ${this.vehicleModel.vehicleMake} ${this.vehicleModel.name}`;
        }
        
        // Update the data on the form
        this.vehicleModelForm.patchValue({
            vehileMake: this.vehicleModel.vehicleMake,
            name: this.vehicleModel.name,
            makeId: this.vehicleModel.makeId,
            id: this.vehicleModel.id
        });

    }

    deleteVehicleModel(): void {
        if (this.vehicleModel.id === 0) {
            // Don't delete, it was never saved.
            this.onSaveComplete();
        } else {
            if (confirm(`Really delete the Vehicle Model: ${this.vehicleModel.vehicleMake} ${this.vehicleModel.name}?`)) {
                this.vehicleModelService.deleteVehicleModel(this.vehicleModel.id)
                    .subscribe({
                        next: () => this.onSaveComplete(),
                        error: err => this.errorMessage = err
                    });
            }
        }
    }

    saveVehicleModel(): void {
        debugger;
        if (this.vehicleModelForm.valid) {
            if (this.vehicleModelForm.dirty) {
                const p = { ...this.vehicleModel, ...this.vehicleModelForm.value };

                if (p.id === 0 || p.id === null) {
                    this.vehicleModelService.createVehicleModel(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                } else {
                    if (p.makeId === undefined) {
                        p.makeId = this.selectedVehicleMake.id;
                        p.vehicleMake = this.selectedVehicleMake.name;
                    }
                    this.vehicleModelService.updateVehicleModel(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                }
            } else {
                this.onSaveComplete();
            }
        } else {
            this.errorMessage = 'Please correct the validation errors.';
        }
    }

    onSaveComplete(): void {
        // Reset the form to clear the flags
        this.vehicleModelForm.reset();
        this.router.navigate(['/dashboard/vehicleModel']);
    }
}

