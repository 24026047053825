import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs'
import { map, tap, catchError } from 'rxjs/operators'
import { Farm } from '../../data-models/farm';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class FarmService {
  private farmsUrl = 'api/farms';

  constructor(private http: HttpClient, ) { }
  getFarms(): Observable<Farm[]> {
    return this.http.get<Farm[]>(this.farmsUrl + '/GetFarms')
      .pipe(
        tap(data => JSON.stringify(data)),
        catchError(this.handleError)
      );
  }

  getFarm(id: number): Observable<Farm> {
    if (id === 0) {
      return of(this.initializeFarm());
    }
    const url = `${this.farmsUrl}/${id}`;

    return this.http.get<Farm>(url)
      .pipe(
        tap(data =>  JSON.stringify(data)),
        catchError(this.handleError)
      );
  }

  createFarm(farm: Farm): Observable<Farm> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    farm.id = null;
    return this.http.post<Farm>(this.farmsUrl + '/SaveFarm', JSON.stringify( farm), { headers })
      .pipe(
        tap(data => console.log('createFarm: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  deleteFarm(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.farmsUrl}/${id}`;
    return this.http.delete<Farm>(url, { headers })
      .pipe(
        tap(data => console.log('deleteFarm: ' + id)),
        catchError(this.handleError)
      );
  }

  updateFarm(farm: Farm): Observable<Farm> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.farmsUrl}/${farm.id}`;
    return this.http.put<Farm>(url, farm, { headers })
      .pipe(
        tap(() => console.log('updateFarm: ' + farm.id)),
        // Return the farm on an update
        map(() => farm),
        catchError(this.handleError)
      );
  }

  private handleError(err) {
    debugger;
      // in a real world app, we may send the server to some remote logging infrastructure
      // instead of just logging it to the console
      let errorMessage: string;
      if (err.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        errorMessage = `An error occurred: ${err.error.message}`;
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        errorMessage = `Backend returned code ${err.status}: ${err.message}`;
      }
      console.error(err);
      return throwError(errorMessage);
    }

  private initializeFarm(): Farm {
    // Return an initialized object
    return {
      id: 0,
      farmName: null,
      farmCode: null,
      latitude: null,
      longitute: null,
      size: null ,
      imageUrl: null

    };
  }
}
