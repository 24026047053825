import { Component, OnInit, ViewChildren, ElementRef, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControlName, FormControl } from '@angular/forms';
import { PlantService } from '../../services/plant.service';
import { PlantVarietyService } from '../../services/plant-variety.service';
import { TraceProductService } from '../../services/trace-product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { Subscription, Observable, fromEvent, merge } from 'rxjs';
import { Plant } from 'src/app/data-models/plant';
import { Trace } from 'src/app/data-models/trace';
import { PlantVariety } from 'src/app/data-models/plant-variety';
import { ProductType } from 'src/app/data-models/productType';
import { Product } from 'src/app/data-models/product';
import { ProductService } from '../../services/product.service';
import { ProductTypeService } from '../../services/product-type.service';
import { TraceService } from '../../services/trace.service';
import { debounceTime } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Farm } from 'src/app/data-models/farm';
import { Field } from 'src/app/data-models/field';
import { FarmService } from '../../services/farm.service';
import { FieldService } from '../../services/field.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlantVarietyEditComponent } from './plant-variety-edit/plant-variety-edit.component';
import { debug } from 'util';
import { TraceProduct } from 'src/app/data-models/trace-product';

@Component({
    selector: 'app-product-edit',
    templateUrl: './product-edit.component.html',
    styleUrls: ['./product-edit.component.css']
})
export class ProductEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];


    errorMessage: string;
    isLinear = false;
    plantFormGroup: FormGroup;
    plantVarietyFormGroup: FormGroup;
    traceProductFormGroup: FormGroup;
    productTypeForm: FormGroup;

    plants: Plant[] = [];
    selectedPlant: PlantVariety  = null;
    selectedFarm: Farm;
    selectedField: Field;
    selectedTrace: Trace;
    plantVariety: PlantVariety = {} as PlantVariety;
    productTypes: ProductType[] = [];
    subProductTypes: ProductType[] = [];
    nextSubProductTypes: ProductType[] = [];
    products: Product[] = [];
    farms: Farm[] = [];
    fields: Field[] = [];
    filteredFields: Field[] = [];
    traces: Trace[] = [];
    filteredTraces: Trace[] = [];
    plantVarieties: PlantVariety[] = [];
    product: Product;
    selectedProductType: ProductType;
    productType: ProductType;
    traceProduct: TraceProduct;

    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;

    private sub: Subscription;


    constructor(private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private plantService: PlantService,
        private plantVarietyService: PlantVarietyService,
        private traceProductService: TraceProductService,
        private productService: ProductService,
        private productTypeService: ProductTypeService,
        private farmService: FarmService,
        private fieldService: FieldService,
        private traceService: TraceService,
        private _snackBar: MatSnackBar) {
        // Defines all of the validation messages for the form.
        // These could instead be retrieved from a file or database.
        this.validationMessages = {
            plantName: {
                required: 'Plant name is required.',
                minlength: 'Plant name must be at least three characters.',
                maxlength: 'Plant name cannot exceed 100 characters.'
            },
            plantParentId: {
                required: 'Parent Id is required.'
            }
        };
        // Define an instance of the validator for use with this form,
        // passing in this form's set of validation messages.
        this.genericValidator = new GenericValidator(this.validationMessages);
    }


    ngOnInit() {
        this.plantFormGroup = this._formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.traceProductFormGroup = this._formBuilder.group({
            productId:[ '', Validators.required],
            traceId: ['', Validators.required],
            quantity: ['', Validators.required],
            addedDate: ['', Validators.required],
            allocationPrice: ['', Validators.required],
            allocationZimPrice: ['', Validators.required],
            farmId: [''],
            fieldId: [''],
            productName: new FormControl({ value: '', disabled: true }, Validators.required),

        });
        this.productTypeForm = this._formBuilder.group({
            id: 0,
            name: ['', [Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100)]],
            parentId: '',
            description: '',
            productTypeId: 0
        });
        // Read the Field Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
            params => {
                // Get a List of farms
                //this.plantService.getPlants().subscribe(
                //    plants => {

                //        this.plants = plants;

                //    });
                // Get a List of Rental Agreements
                this.productTypeService.getProductTypeByParentId(1).subscribe(
                    items => {
                        this.productTypes = items;
                        // this.selectedProductType = this.productTypes[0];

                    });
                this.farmService.getFarms().subscribe(
                    farms => {
                        this.farms = farms;

                    });
                this.fieldService.getFields().subscribe(
                    results => {
                        this.fields = results;
                    }
                );
                this.traceService.getTraces().subscribe(
                    results => {
                        this.traces = results;
                    }
                );
                const id = +params.get('id');


            }
        );



    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    ngAfterViewInit(): void {
        // Watch for the blur event from any input element on the form.
        // This is required because the valueChanges does not provide notification on blur
        const controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

        // Merge the blur event observable with the valueChanges observable
        // so we only need to subscribe once. 
        merge(this.productTypeForm.valueChanges, ...controlBlurs).pipe(
            debounceTime(800)
        ).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.productTypeForm);
        });
    }

    displayProductType(productType: ProductType): void {
        if (this.productTypeForm) {
            this.productTypeForm.reset();
        }
        productType = this.selectedProductType;

        //if (this.field.id === 0) {
        //    this.pageTitle = 'Add Field';
        //} else {
        //    this.pageTitle = `Edit Field: ${this.field.fieldName}`;
        //}

        // Update the data on the form
        this.productTypeForm.patchValue({
            name: this.productType.name,
            description: this.productType.description,
            parentId: this.productType.parentId,
            id: productType.id
        });
        //this.farmForm.setControl('tags', this.fb.array(this.farm.tags || []));
    }

    saveProductType(): void {
        if (this.productTypeForm.valid) {
            if (this.productTypeForm.dirty) {
                this.productTypeForm.get("id").setValue(0, {
                    onlySelf: true
                });

                const p = { ...this.productType, ...this.productTypeForm.value };

                //p.name = this.productTypeForm.get("productTypeName");
                //p.description = this.productTypeForm.get("productTypeDescription");
                this.productTypeService.createProductType(p)
                    .subscribe({
                        next: () => this.onSaveComplete(p.name),
                        error: err => this.errorMessage = err
                    });

            } else {
                this.onSaveComplete("nothing to save");
            }
        } else {
            this.errorMessage = 'Please correct the validation errors.';
        }
    }
    saveTraceProduct(): void{

        if (this.traceProductFormGroup.valid) {
            if (this.traceProductFormGroup.dirty) {
                debugger;
                const p = { ...this.traceProduct, ...this.traceProductFormGroup.value };
                this.traceProductService.createTraceProduct(p)
                    .subscribe({
                        next: () => this.onSaveComplete(p.name),
                        error: err => this.errorMessage = err
                    });

            } else {
                this.onSaveComplete("nothing to save");
            }
        } else {
            this.errorMessage = 'Please correct the validation errors.';
        }
    }
    // Choose farm using select dropdown
    changeProductType(id) {
        this.selectedProductType = null;
        this.subProductTypes = [];
        for (var i = 0; i < this.productTypes.length; i++) {
            if (this.productTypes[i].id == id) {
                this.selectedProductType = this.productTypes[i];
                console.log(this.productTypes[i].name);
            }
        }

        this.getProductTypes(id);
        this.productTypeForm.get("parentId").setValue(id, {
            onlySelf: true
        });
        
    }
    // Choose farm using select dropdown
    changeSubProductType(id) {

        if (this.subProductTypes) {
            this.productTypes = this.subProductTypes;

            this.subProductTypes = [];

            for (var i = 0; i < this.productTypes.length; i++) {
                if (this.productTypes[i].id == id) {
                    this.selectedProductType = this.productTypes[i];
                    console.log(this.productTypes[i].name);
                }
            }

            this.getProductTypes(id);

            this.productTypeForm.get("id").setValue(id, {
                onlySelf: true
            });

            this.productTypeForm.get("parentId").setValue(id, {
                onlySelf: true
            });
            this.getPlantVarieties(id);
        }
    }
    // Choose farm using select dropdown
    changeFarm(id) {
        this.filteredFields = [];
        for (var i = 0; i < this.farms.length; i++) {
            if (this.farms[i].id == id) {
                this.selectedFarm= this.farms[i];
                console.log(this.farms[i].farmName);
            }
        }
        
            this.filteredFields = this.fields.filter((field: Field) => field.farmId == id);
        
    }
    // Choose farm using select dropdown
    changeField(id) {
        
        this.filteredTraces= [];
        for (var i = 0; i < this.fields.length; i++) {
            if (this.fields[i].id == id) {
                this.selectedField = this.fields[i];
                console.log(this.fields[i].fieldName);
            }
        }
        this.filteredTraces = this.traces.filter((trace: Trace) =>  trace.fieldId== id);
        
    }
    changeTrace(id) {
        this.selectedTrace = null;
        for (var i = 0; i < this.traces.length; i++) {
            if (this.traces[i].id == id) {
                this.selectedTrace = this.traces[i];
                console.log(this.traces[i].code);
            }
        }
        this.traceProductFormGroup.get("traceId").setValue(id, {
            onlySelf: true
        });

    }

    refreshProduct() {
        // Get a List of Crops
        this.productTypeService.getProductTypeByParentId(1).subscribe(
            items => {
                this.productTypes = items;
        
            });
        this.subProductTypes = [];
        this.selectedProductType = null;

    }

    onSaveComplete(message: string): void {

        // Reset the form to clear the flags
        this.productTypeForm.reset();
        this.traceProductFormGroup.reset();

        this._snackBar.open(message, " was successfully saved   ", {
            duration: 3000,
        });
    }

    getProductTypes(id: number): void {
        var results: ProductType[] = [];
        if (id > 0) {
            this.productTypeService.getProductTypeByParentId(id).subscribe(
                items => {
                    if (items) {

                        this.subProductTypes = items;
                    }
                });


        }

    }

    openPlantVarietyDialog(): void {

        this.plantVariety.productTypeId = this.selectedProductType.id;
        const dialogRef = this.dialog.open(PlantVarietyEditComponent, {
            width: '800px',
            data: this.plantVariety
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.getPlantVarieties(this.plantVariety.productTypeId);
            this.plantVariety = result;

        });
    }
    editPlantVarietyDialog(item): void {

        
        const dialogRef = this.dialog.open(PlantVarietyEditComponent, {
            width: '800px',
            data: item
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getPlantVarieties(this.plantVariety.productTypeId);
            console.log('The dialog was closed');
            this.selectedPlant = this.plantVariety = result;
        });
    }
    getPlantVarieties(id: number): void {
        this.plantVarieties= [];
        if (id > 0) {
            this.plantVarietyService.getPlantVarietiesByProductTypeId(id).subscribe(
                items => {
                    if (items) {

                        this.plantVarieties= items;
                    }
                });


        }
  
    }
    AddPlantVarietyToTrace(item) {
        
        this.selectedPlant = item;
        this.productService.getProductByProductTypeAndReference(this.selectedPlant.productTypeId, this.selectedPlant.id).subscribe(
            product => {
                this.product = product;
                this.traceProductFormGroup.get("productId").setValue(this.product.id, {
                    onlySelf: true
                });
                this.traceProductFormGroup.get("productName").setValue(this.product.name, {
                    onlySelf: true
                });

            }
        );
    }
}

