import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/modules/shared.module';

import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { VehicleModelEditComponent } from './vehicle-model-edit.component';
import { VehicleModelDetailComponent } from './vehicle-model-detail.component';
import { VehicleModelComponent } from './vehicle-model.component';
import { VehicleModelEditGuard } from './vehicle-model-edit.guard';



@NgModule({
    imports: [
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        DataTablesModule,
        RouterModule.forChild([
            { path: 'vehicleModel', component: VehicleModelComponent },
            { path: 'vehicleModel/:id', component: VehicleModelDetailComponent },
            {
                path: 'vehicleModel/:id/edit',
                canDeactivate: [VehicleModelEditGuard],
                component: VehicleModelEditComponent
            }
        ])
    ],
    declarations: [
        VehicleModelComponent,
        VehicleModelDetailComponent,
        VehicleModelEditComponent
    ]
})
export class VehicleModelModule { }
