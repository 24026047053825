import { Component, OnInit } from '@angular/core';
import { VehicleModel } from '../../../data-models/vehiclemodel';
import { VehicleModelService } from '../../services/vehicle-model.service';

@Component({
  selector: 'app-vehicle-model',
  templateUrl: './vehicle-model.component.html',
  styleUrls: ['./vehicle-model.component.css']
})
export class VehicleModelComponent implements OnInit {

    pageTitle = 'Vehicle Model List';
    imageWidth = 50;
    imageMargin = 2;
    showImage = false;
    errorMessage = '';

    _listFilter = '';
    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredVehicleModels = this.listFilter ? this.performFilter(this.listFilter) : this.vehicleModels;
    }

    filteredVehicleModels: VehicleModel[] = [];
    vehicleModels: VehicleModel[] = [];
    //dtOptions: DataTables.Settings = {};
    dtOptions: any = {};
    constructor(private vehicleModelService: VehicleModelService) { }

    performFilter(filterBy: string): VehicleModel[] {

        filterBy = filterBy.toLocaleLowerCase();
        return this.vehicleModels.filter((vehicleModel: VehicleModel) =>
            vehicleModel.name.toLocaleLowerCase().indexOf(filterBy) !== -1

        );
    }

    toggleImage(): void {
        this.showImage = !this.showImage;
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            dom: 'Bfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'print'
            ]
        };
        this.vehicleModelService.getVehicleModels().subscribe({
            next: item => {

                this.vehicleModels = item;
                this.filteredVehicleModels = this.vehicleModels;

            },
            error: err => this.errorMessage = err
        });
    }
}
