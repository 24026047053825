import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

import { EmployeeTrip } from '../../data-models/employeetrip';
import { InterceptorMetaOptions } from '../../data-models/InterceptorMetaOptions';
import { catchError, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FileUploadServiceService {

    // API url
    private baseApiUrl = "api/EmployeeTrip/FileUpLoad"
    private routerInfo: any;
    constructor(private http: HttpClient,
        private router: Router) {
        console.log(router.url);
        
        this.routerInfo = router;//.location._platformLocation.location.origin;
    }

    // Returns an observable
    upload(file): Observable<any> {
        // Create form data
        const formData = new FormData();

        const headers = new HttpHeaders({ 'metadata': 'true', 'isFile': 'true' });
        // Store form name as "file" with file data
        formData.append("file", file, file.name);
        
        // Make http post request over api
        // with formData as req
        return this.http.post<EmployeeTrip>(this.baseApiUrl, formData, { headers: headers, reportProgress: true, observe: 'events' })
            .pipe(
                tap(data => data),
                catchError(this.handleError)
            );
    }
    private handleError(err) {
        debugger;
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }

    public uploadFile = (files) => {
        if (files.length === 0) {
            return;
        }
        
        const headers = new HttpHeaders({ 'isFile': 'true' });
        let fileToUpload = files;
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        this.http.post<EmployeeTrip>(this.baseApiUrl, formData, { headers: headers, reportProgress: true, observe: 'events' })
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress)
                    console.log( Math.round(100 * event.loaded / event.total));
                else if (event.type === HttpEventType.Response) {
                    console.log( 'Upload success.');
                    
                }
            });
    }
}
