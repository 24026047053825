import { Component, OnInit, Inject, ViewChildren, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlantVariety } from 'src/app/data-models/plant-variety';
import { FormBuilder, FormControlName, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PlantVarietyService } from 'src/app/dashboard/services/plant-variety.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable, fromEvent, merge } from 'rxjs';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { debounceTime } from 'rxjs/operators';
import { debug } from 'util';
import { ProductService } from 'src/app/dashboard/services/product.service';
import { Product } from 'src/app/data-models/product';

@Component({
    selector: 'app-plant-variety-edit',
    templateUrl: './plant-variety-edit.component.html',
    styleUrls: ['./plant-variety-edit.component.css']
})
export class PlantVarietyEditComponent implements OnInit, AfterViewInit, OnDestroy  {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    plantVariety: PlantVariety;
    product: Product;
    errorMessage: string;
    pageTitle: string = "Add Plantvariety";
    plantVarietyForm: FormGroup;
    private sub: Subscription;
    private productTypeId: number = 0;
    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;

    constructor(public dialogRef: MatDialogRef<PlantVarietyEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PlantVariety,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private plantVarietyService: PlantVarietyService,
        private productService: ProductService,
        private _snackBar: MatSnackBar) {
        this.plantVariety = data;
        this.productTypeId = data.productTypeId;
        // Defines all of the validation messages for the form.
        // These could instead be retrieved from a file or database.
        this.validationMessages = {
            name: {
                required: 'Plant Variety name is required.',
                minlength: 'Plant Variety name must be at least three characters.',
                maxlength: 'Plant Variety name cannot exceed 100 characters.'
            },
            code: {
                required: 'Plant Variety code is required.',
                minlength:'Plant Variety code must be at least three characters.'
            }
        };

        // Define an instance of the validator for use with this form,
        // passing in this form's set of validation messages.
        this.genericValidator = new GenericValidator(this.validationMessages);

    }

    onNoClick(): void {
        debugger;
        this.dialogRef.close();
    }

    ngOnInit() {
        
        this.plantVarietyForm = this.fb.group({
            name: ['', [Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100)]],
            code: ['', [Validators.required,
            Validators.minLength(3)]],
            timeToGerminate: [''],
            timeToFirstHarvest: [''],
            totalDaysToEndOfCrop: [''],
            id: 0,
            productTypeId: this.productTypeId,
            price: '',
            zimPrice: '',
            description:''
        });

        // Read the farm Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
            params => {
                const id = +params.get('id');
                if (id && id > 0) {
                    this.getPlantVariety(id);
                }
                if (this.plantVariety.id > 0) {
                    this.getPlantVariety(this.plantVariety.id);
                    this.getProduct(this.plantVariety.productTypeId, this.plantVariety.id);
                }
                
            }
        );

    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    ngAfterViewInit(): void {
        // Watch for the blur event from any input element on the form.
        // This is required because the valueChanges does not provide notification on blur
        const controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

        // Merge the blur event observable with the valueChanges observable
        // so we only need to subscribe once.
        merge(this.plantVarietyForm.valueChanges, ...controlBlurs).pipe(
            debounceTime(800)
        ).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.plantVarietyForm);
        });
    }
    getPlantVariety(id: number): void {
        this.plantVarietyService.getPlantVariety(id)
            .subscribe({
                next: (plantVariety: PlantVariety) => {
                    debugger;
                    if (plantVariety.productTypeId === 0) {
                        plantVariety.productTypeId = this.productTypeId;
                    }
                    this.displayplantVariety(plantVariety);
                },
                error: err => this.errorMessage = err
            });
    }
    getProduct(productTypeId: number, id: number): void{
        this.productService.getProductByProductTypeAndReference(productTypeId, id).subscribe(
            product => {
                this.product = product;
                this.plantVarietyForm.get("code").setValue(this.product.code, {
                    onlySelf: true
                });
                this.plantVarietyForm.get("description").setValue(this.product.description, {
                    onlySelf: true
                });
                this.plantVarietyForm.get("price").setValue(this.product.price, {
                    onlySelf: true
                });
                this.plantVarietyForm.get("zimPrice").setValue(this.product.zimPrice, {
                    onlySelf: true
                });
            }
        );
    }
    displayplantVariety(plantVariety: PlantVariety): void {
        if (this.plantVarietyForm) {
            this.plantVarietyForm.reset();
        }
        this.plantVariety = plantVariety;

        if (this.plantVariety.id === 0) {
            this.pageTitle = 'Add Plant Variety';
        } else {
            this.pageTitle = 'Edit Plant Variety: ' + this.plantVariety.name;
        }
        
        // Update the data on the form
        this.plantVarietyForm.patchValue({
            name: this.plantVariety.name,
            code: this.plantVariety.code,
            productTypeId:this.plantVariety.productTypeId,
            timeToFirstHarvest: this.plantVariety.timeToFirstHarvest,
            timeToGerminate: this.plantVariety.timeToGerminate,
            totalDaysToEndOfCrop: this.plantVariety.totalDaysToEndOfCrop,
            id: this.plantVariety.id,
            price: this.plantVariety.price,
            description: this.plantVariety.description,
            zimPrice: this.plantVariety.zimPrice
            
        });
        //this.farmForm.setControl('tags', this.fb.array(this.farm.tags || []));
    }

    deletePlantVariety(): void {
        if (this.plantVariety.id === 0) {
            // Don't delete, it was never saved.
            this.onSaveComplete();
        } else {
            if (confirm('Really delete the plant Variety: ${this.plantVariety.name}?')) {
                this.plantVarietyService.deletePlantVariety(this.plantVariety.id)
                    .subscribe({
                        next: () => this.onSaveComplete(),
                        error: err => this.errorMessage = err
                    });
            }
        }
    }

    savePlantVariety(): void {
        if (this.plantVarietyForm.valid) {
            if (this.plantVarietyForm.dirty) {
                const p = { ...this.plantVariety, ...this.plantVarietyForm.value };

                if (p.id === 0) {
                    this.plantVarietyService.createPlantVariety(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                } else {
                    this.plantVarietyService.updatePlantVariety(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                }
            } else {
                this.onSaveComplete();
            }
        } else {
            this.errorMessage = 'Please correct the validation errors.';
        }
    }

    onSaveComplete(): void {
        // Reset the form to clear the flags
        this.plantVarietyForm.reset();
        this._snackBar.open(this.plantVariety.name, " was successfully saved   ", {
            duration: 3000,
        });
        this.dialogRef.close();
        
    }
}
