import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { EmployeeEditComponent } from './employee-edit.component';

@Injectable({
  providedIn: 'root'
})
export class EmployeeEditGuard implements CanDeactivate<EmployeeEditComponent> {
    canDeactivate(component: EmployeeEditComponent): Observable<boolean> | Promise<boolean> | boolean {
        if (component.employeeForm.dirty) {
            const firstName = component.employeeForm.get('firstName').value || 'New employee';
            return confirm(`Navigate away and lose all changes to ${firstName}?`);
        }
        return true;
    }
}