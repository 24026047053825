import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { VehicleEditComponent } from './vehicle-edit.component';

@Injectable({
  providedIn: 'root'
})
export class VehicleEditGuard implements CanDeactivate<VehicleEditComponent> {
    canDeactivate(component: VehicleEditComponent): Observable<boolean> | Promise<boolean> | boolean {
        if (component.vehicleForm.dirty) {
            const vehicleModel = component.vehicleForm.get('VehicleModel').value || 'New Vehicle';
            return confirm(`Navigate away and lose all changes to ${vehicleModel}?`);
        }
        return true;
    }
}