import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';


// Imports for loading & configuring the in-memory web api
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { SharedModule } from '../../../shared/modules/shared.module';

import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { EmployeetripEditComponent } from './employeetrip-edit.component';
import { EmployeetripDetailComponent } from './employeetrip-detail.component';
import { EmployeetripComponent } from './employeetrip.component';
import { EmployeetripGuard } from './employeetrip.guard';


@NgModule({
    imports: [
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        DataTablesModule,
        RouterModule.forChild([
            { path: 'employeetrip', component: EmployeetripComponent },
            { path: 'employeetrip/:id', component: EmployeetripDetailComponent },
            {
                path: 'employeetrip/:id/edit',
                canDeactivate: [EmployeetripGuard],
                component: EmployeetripEditComponent
            }
        ])
    ],
    declarations: [
        EmployeetripComponent,
        EmployeetripDetailComponent,
        EmployeetripEditComponent
    ]
})
export class EmployeeTripModule { }
