import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleMake } from '../../../data-models/vehiclemake';
import { VehicleMakeService } from '../../services/vehicle-make.service';

@Component({
  selector: 'app-vehicle-make-detail',
  templateUrl: './vehicle-make-detail.component.html',
  styleUrls: ['./vehicle-make-detail.component.css']
})
export class VehicleMakeDetailComponent implements OnInit {
    pageTitle = 'Employee Detail';
    errorMessage = '';
    vehicleMake: VehicleMake | undefined;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private vehicleMakeService: VehicleMakeService
        ) {
    }

    ngOnInit() {
        const param = this.route.snapshot.paramMap.get('id');
        if (param) {
            const id = +param;
            this.getVehicleMake(id);

        }
    }

    getVehicleMake(id: number) {
        this.vehicleMakeService.getVehicleMake(id).subscribe({
            next: model => {
                this.vehicleMake = model;
                
            },
            error: err => this.errorMessage = err
        });
    }
    onBack(): void {
        this.router.navigate(['/dashboard/vehicleMake']);
    }

}
