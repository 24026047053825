import { Injectable } from '@angular/core';
import { Plant } from 'src/app/data-models/plant';
import { throwError, Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { PlantVariety } from 'src/app/data-models/plant-variety';

@Injectable({
  providedIn: 'root'
})
export class PlantVarietyService {
    private plantVarietiesUrl = 'api/PlantVarieties';
    
    constructor(private http: HttpClient, ) { }
    getPlantVarieties(): Observable<PlantVariety[]> {
        return this.http.get<PlantVariety[]>(this.plantVarietiesUrl + '/GetPlantVarieties')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }
    getPlantVarietiesByProductTypeId(id: number): Observable<PlantVariety[]> {
        if (id === 0) {
            //return of(this.initializeProductType());
        }
        return this.http.get<PlantVariety[]>(this.plantVarietiesUrl + '/' + id + '/GetPlantVarietiesByProductTypeId')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    getPlantVariety(id: number): Observable<PlantVariety> {
        if (id === 0) {
            return of(this.initializePlantVariety());
        }
        const url = `${this.plantVarietiesUrl}/${id}`;

        return this.http.get<PlantVariety>(url)
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    createPlantVariety(plantVariety: PlantVariety): Observable<PlantVariety> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        plantVariety.id = null;
        return this.http.post<PlantVariety>(this.plantVarietiesUrl + '/SavePlantVariety', JSON.stringify(plantVariety), { headers })
            .pipe(
                tap(data => console.log('createPlantVariety: ' + JSON.stringify(data))),
                catchError(this.handleError)
            );
    }

    deletePlantVariety(id: number): Observable<{}> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.plantVarietiesUrl}/${id}`;
        return this.http.delete<PlantVariety>(url, { headers })
            .pipe(
                tap(data => console.log('deletePlantVariety: ' + id)),
                catchError(this.handleError)
            );
    }

    updatePlantVariety(plantVariety: PlantVariety): Observable<PlantVariety> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.plantVarietiesUrl}/${plantVariety.id}`;
        return this.http.put<PlantVariety>(url, plantVariety, { headers })
            .pipe(
                tap(() => console.log('updatePlantVariety: ' + plantVariety.id)),
                // Return the field on an update
                map(() => plantVariety),
                catchError(this.handleError)
            );
    }

    private handleError(err) {
        debugger;
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }
    
    private initializePlantVariety(): PlantVariety {
        // Return an initialized object
        return {
            id: 0,
            productTypeId: 0,
            name: null,
            SupplierId: null,
            timeToFirstHarvest: null,
            timeToGerminate: null,
            totalDaysToEndOfCrop: null,
            plantTypeName: null,
            code: null,
            description: null,
            price: null,
            zimPrice:null
            
        };
    }
}
