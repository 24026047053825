import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleMake } from '../../../data-models/vehiclemake';
import { VehicleModel } from '../../../data-models/vehiclemodel';
import { VehicleMakeService } from '../../services/vehicle-make.service';
import { VehicleModelService } from '../../services/vehicle-model.service';

@Component({
  selector: 'app-vehicle-model-detail',
  templateUrl: './vehicle-model-detail.component.html',
  styleUrls: ['./vehicle-model-detail.component.css']
})
export class VehicleModelDetailComponent implements OnInit {
    pageTitle = 'Employee Detail';
    errorMessage = '';
    vehicleMake: VehicleMake | undefined;
    vehicleModel: VehicleModel | undefined;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private vehicleMakeService: VehicleMakeService,
        private vehicleModelService: VehicleModelService) {
    }

    ngOnInit() {
        const param = this.route.snapshot.paramMap.get('id');
        if (param) {
            const id = +param;
            this.getVehicleModel(id);

        }
    }

    getVehicleModel(id: number) {
        this.vehicleModelService.getVehicleModel(id).subscribe({
            next: vehicleModel => {
                this.vehicleModel = this.vehicleModel;
                this.getVehicleMake(+vehicleModel.makeId);
            },
            error: err => this.errorMessage = err
        });
    }
    getVehicleMake(id: number) {
        this.vehicleMakeService.getVehicleMake(id).subscribe({
            next: vehicleMake => {
                this.vehicleMake = vehicleMake;
                this.vehicleModel.vehicleMake = vehicleMake.name;
            },
            error: err => this.errorMessage = err
        });
    }
    onBack(): void {
        this.router.navigate(['/dashboard/vehicleModel']);
    }

}
