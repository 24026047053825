import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/modules/shared.module';

import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { VehicleEditComponent } from './vehicle-edit.component';
import { VehicleEditGuard } from './vehicle.guard';
import { VehicleDetailComponent } from './vehicle-detail.component';
import { VehicleComponent } from './vehicle.component';



@NgModule({
    imports: [
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        DataTablesModule,
        RouterModule.forChild([
            { path: 'vehicle', component: VehicleComponent },
            { path: 'vehicle/:id', component: VehicleDetailComponent },
            {
                path: 'vehicle/:id/edit',
                canDeactivate: [VehicleEditGuard],
                component: VehicleEditComponent
            }
        ])
    ],
    declarations: [
        VehicleComponent,
        VehicleDetailComponent,
        VehicleEditComponent
    ]
})

export class VehicleModule { }
