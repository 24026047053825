import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule} from '@angular/forms';


// Imports for loading & configuring the in-memory web api
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FarmEditComponent } from './farm.edit.component';
import { FarmDetailComponent } from './farm.detail.component';
import { FarmComponent } from './farm.component';
import { SharedModule } from '../../../shared/modules/shared.module';
import { FarmEditGuard } from './farm-edit.guard';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
  imports: [
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    DataTablesModule,
    RouterModule.forChild([
      { path: 'farms', component: FarmComponent },
      { path: 'farms/:id', component: FarmDetailComponent },
      {
        path: 'farms/:id/edit',
        canDeactivate: [FarmEditGuard],
        component: FarmEditComponent
      }
         ])
  ],
  declarations: [
    FarmComponent,
    FarmDetailComponent,
    FarmEditComponent
  ]
})
export class FarmModule { }
