import { Component, OnInit, ViewChildren, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName, FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription, Observable, fromEvent, merge } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { debug } from 'util';
import { Field } from 'src/app/data-models/field';
import { Trace } from 'src/app/data-models/trace';
import { FieldService } from '../../services/field.service';
import { TraceService } from '../../services/trace.service';
import { GenericValidator } from 'src/app/shared/generic-validator';

@Component({
    selector: 'app-trace-edit',
    templateUrl: './trace-edit.component.html',
    styleUrls: ['./trace-edit.component.css']
})
export class TraceEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    pageTitle = 'Trace Edit';
    errorMessage: string;
    traceForm: FormGroup;

    trace: Trace;
    fields: Field[];
    selectedField: Field;
    private sub: Subscription;

    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;

    get tags(): FormArray {
        return this.traceForm.get('tags') as FormArray;
    }

    constructor(private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private fieldService: FieldService,
        private traceService: TraceService
    ) {

        // Defines all of the validation messages for the form.
        // These could instead be retrieved from a file or database.
        this.validationMessages = {
            code: {
                required: 'Field code is required.'
            },
            fieldId: {
                required: 'You must select a field'
            },
            irrigationTypeId: {
                required: 'You must select an Irrigation Type'
            }
        };

        // Define an instance of the validator for use with this form,
        // passing in this form's set of validation messages.
        this.genericValidator = new GenericValidator(this.validationMessages);
    }

    ngOnInit(): void {
        this.traceForm = this.fb.group({
            code: ['', Validators.required],
            fieldId: ['', Validators.required],
            irrigationTypeId: [''],
            plantSpacing: '',
            fieldName: '',
            fieldCode: '',
            irrigation: '',
            size: '',
            bedQuantity:'',
            bedSpacing:'',
            bedLength: ''

        });

        // Read the Field Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
            params => {

                const id = +params.get('id');
                this.getTrace(id);
                if (this.trace !== null) {
                    this.getField(parseInt(this.trace.fieldId));
                }

                this.changeField(this.trace.fieldId);
            }
        );
        // Get a List of fields
        this.fieldService.getFields().subscribe(
            fields => {

                this.fields = fields;
                this.selectedField = this.fields[0];

            });

    }


    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    ngAfterViewInit(): void {
        // Watch for the blur event from any input element on the form.
        // This is required because the valueChanges does not provide notification on blur
        const controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

        // Merge the blur event observable with the valueChanges observable
        // so we only need to subscribe once.
        merge(this.traceForm.valueChanges, ...controlBlurs).pipe(
            debounceTime(800)
        ).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.traceForm);
        });
    }

    addTag(): void {
        this.tags.push(new FormControl());
    }

    deleteTag(index: number): void {
        this.tags.removeAt(index);
        this.tags.markAsDirty();
    }

    getTrace(id: number): void {
        this.traceService.getTrace(id)
            .subscribe({
                next: (trace: Trace) => this.displayTrace(trace),
                error: err => this.errorMessage = err
            });
    }
    getField(id: number): void {
        if (id > 0) {
            this.fieldService.getField(id)
                .subscribe({
                    next: (field: Field) => this.selectedField = field,
                    error: err => this.errorMessage = err
                });
        }

    }

    displayTrace(trace: Trace): void {
        if (this.traceForm) {
            this.traceForm.reset();
        }
        this.trace = trace;

        if (this.trace.id === 0) {
            this.pageTitle = 'Add Trace';
        } else {
            this.pageTitle = `Edit Trace: ${this.trace.code}`;
        }

        // Update the data on the form
        this.traceForm.patchValue({
            fieldName: this.trace.fieldName,
            fieldCode: this.trace.fieldCode,
            code: this.trace.code,
            plantSpacing: this.trace.plantSpacing,
            fieldId: this.trace.fieldId,
            irrigationTypeId: this.trace.irrigationTypeId,
            irrigation: this.trace.irrigation,
            size: this.trace.size,
            bedQuantity: this.trace.bedQuantity,
            bedSpacing: this.trace.bedSpacing,
            bedLength: this.trace.bedLength

        });
        //this.farmForm.setControl('tags', this.fb.array(this.farm.tags || []));
    }

    deleteTrace(): void {
        if (this.trace.id === 0) {
            // Don't delete, it was never saved.
            this.onSaveComplete();
        } else {
            if (confirm('Really delete the trace: ${this.trace.code}?')) {
                this.traceService.deleteTrace(this.trace.id)
                    .subscribe({
                        next: () => this.onSaveComplete(),
                        error: err => this.errorMessage = err
                    });
            }
        }
    }

    saveTrace(): void {
        if (this.traceForm.valid) {
            if (this.traceForm.dirty) {
                const p = { ...this.trace, ...this.traceForm.value };

                if (p.id === 0) {
                    this.traceService.createTrace(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                } else {
                    this.traceService.updateTrace(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                }
            } else {
                this.onSaveComplete();
            }
        } else {
            this.errorMessage = 'Please correct the validation errors.';
        }
    }
    // Choose farm using select dropdown
    changeField(fieldId) {

        this.selectedField = null;
        debugger;

        for (var i = 0; i < this.fields.length; i++) {
            if (this.fields[i].id == fieldId) {
                this.selectedField = this.fields[i];
            }
        }
        //this.fieldForm.get("farm").setValue(this.selectedFarm, {
        //  onlySelf: true
        //});
        this.traceForm.get("fieldId").setValue(fieldId, {
            onlySelf: true
        });
        this.traceForm.get("fieldName").setValue(this.selectedField.fieldName, {
            onlySelf: true
        });
        this.traceForm.get("fieldCode").setValue(this.selectedField.code, {
            onlySelf: true
        });
    }
    // Choose rental Agreement using select dropdown
    changeIrrigationType(irrigationTypeId) {

        this.traceForm.get("irrigationTypeId").setValue(irrigationTypeId, {
            onlySelf: true
        });

    }
    onSaveComplete(): void {
        // Reset the form to clear the flags
        this.traceForm.reset();
        this.router.navigate(['/dashboard/trace']);
    }
}
