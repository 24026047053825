import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChildren, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName, FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { VehicleMake } from '../../../data-models/vehiclemake';
import { GenericValidator } from '../../../shared/generic-validator';
import { VehicleMakeService } from '../../services/vehicle-make.service';

@Component({
  selector: 'app-vehicle-make-edit',
  templateUrl: './vehicle-make-edit.component.html',
  styleUrls: ['./vehicle-make-edit.component.css']
})
export class VehicleMakeEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    pageTitle = 'Vehicle Make Edit';
    errorMessage: string;
    vehicleMakeForm: FormGroup;
    vehicleMakes: VehicleMake[];
    vehicleMake: VehicleMake;
    private sub: Subscription;

    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;



    constructor(private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private vehicleMakeService: VehicleMakeService,
        public datepipe: DatePipe
    ) {

        // Defines all of the validation messages for the form.
        // These could instead be retrieved from a file or database.
        this.validationMessages = {

            
            name: {
                required: 'Vehicle Make is required.'
            }
        };

        // Define an instance of the validator for use with this form,
        // passing in this form's set of validation messages.
        this.genericValidator = new GenericValidator(this.validationMessages);

    }

    ngOnInit(): void {
        this.vehicleMakeForm = this.fb.group({
            name: ['', Validators.required],
            id: 0
        });

        // Read the employee Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
            params => {
                
                const id = +params.get('id');
                this.getVehicleMake(id);
            }
        );
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    ngAfterViewInit(): void {
        // Watch for the blur event from any input element on the form.
        // This is required because the valueChanges does not provide notification on blur
        const controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

        // Merge the blur event observable with the valueChanges observable
        // so we only need to subscribe once.
        merge(this.vehicleMakeForm.valueChanges, ...controlBlurs).pipe(
            debounceTime(800)
        ).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.vehicleMakeForm);
        });
    }


    getVehicleMake(id: number): void {
        this.vehicleMakeService.getVehicleMake(id)
            .subscribe({
                next: (vehicleMake: VehicleMake) => {
                    this.displayVehicleMake(vehicleMake);
                    
                },
                error: err => this.errorMessage = err
            });
    }
    
    displayVehicleMake(vehicleMake: VehicleMake): void {
        if (this.vehicleMakeForm) {
            this.vehicleMakeForm.reset();
        }
        this.vehicleMake = vehicleMake;

        if (this.vehicleMake.id === 0) {
            this.pageTitle = 'Add Vehicle Make';
        } else {
            this.pageTitle = `Edit Vehicle Make: ${this.vehicleMake.name} `;
        }
        
        // Update the data on the form
        this.vehicleMakeForm.patchValue({
            name: this.vehicleMake.name,
            id: this.vehicleMake.id
        });

    }

    deleteVehicleMake(): void {
        if (this.vehicleMake.id === 0) {
            // Don't delete, it was never saved.
            this.onSaveComplete();
        } else {
            if (confirm(`Really delete the Vehicle Make: ${this.vehicleMake.name}?`)) {
                this.vehicleMakeService.deleteVehicleMake(this.vehicleMake.id)
                    .subscribe({
                        next: () => this.onSaveComplete(),
                        error: err => this.errorMessage = err
                    });
            }
        }
    }

    saveVehicleMake(): void {
        
        if (this.vehicleMakeForm.valid) {
            if (this.vehicleMakeForm.dirty) {
                const p = { ...this.vehicleMake, ...this.vehicleMakeForm.value };

                if (p.id === 0 || p.id === null) {
                    this.vehicleMakeService.createVehicleMake(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                } else {
                    
                    this.vehicleMakeService.updateVehicleMake(p)
                        .subscribe({
                            next: () => this.onSaveComplete(),
                            error: err => this.errorMessage = err
                        });
                }
            } else {
                this.onSaveComplete();
            }
        } else {
            this.errorMessage = 'Please correct the validation errors.';
        }
    }

    onSaveComplete(): void {
        // Reset the form to clear the flags
        this.vehicleMakeForm.reset();
        this.router.navigate(['/dashboard/vehicleMake']);
    }
}

