import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseService } from "./base.service";
import { Observable, throwError, of } from "rxjs";
import { Company } from "../../data-models/company";
import { map, catchError, tap } from "rxjs/operators";

@Injectable(
  
)
export class CompanyService {
    private companyUrl = 'api/Company';
  
  constructor(private http: HttpClient, ) { }
    getCompanies(): Observable<Company[]> {
        return this.http.get<Company[]>(this.companyUrl + '/GetCompanies')
      .pipe(
        tap(data => JSON.stringify(data)),
        catchError(this.handleError)
      );
  }

    getCompany(id: number): Observable<Company> {
    if (id === 0) {
        return of(this.initializeCompany());
    }
    const url = `${this.companyUrl}/${id}`;

        return this.http.get<Company>(url)
      .pipe(
        tap(data => JSON.stringify(data)),
        catchError(this.handleError)
      );
  }

    createCompany(company: Company): Observable<Company> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    company.id = null;
        return this.http.post<Company>(this.companyUrl + '/SaveCompany', JSON.stringify(company), { headers })
      .pipe(
          tap(data => console.log('createCompany: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  deleteRentalAgreement(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.companyUrl}/${id}`;
      return this.http.delete<Company>(url, { headers })
      .pipe(
          tap(data => console.log('deleteCompany: ' + id)),
        catchError(this.handleError)
      );
  }

    updateCompany(company: Company): Observable<Company> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.companyUrl}/${company.id}`;
        return this.http.put<Company>(url, company, { headers })
      .pipe(
      tap(() => console.log('updateRentalAgreement: ' + company.id)),
        // Return the RentalAgreement on an update
      map(() => company),
        catchError(this.handleError)
      );
  }

  private handleError(err) {
    debugger;
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }

    private initializeCompany(): Company {
    // Return an initialized object
    return {
      id: 0,
      name: null,
      registrationNumber: null
      
    };
  }
}
