import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/modules/shared.module';

import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { VehicleMakeComponent } from './vehicle-make.component';
import { VehicleMakeEditGuard } from './vehicle-make-edit.guard';
import { VehicleMakeEditComponent } from './vehicle-make-edit.component';
import { VehicleMakeDetailComponent } from './vehicle-make-detail.component';


@NgModule({
    imports: [
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        DataTablesModule,
        RouterModule.forChild([
            { path: 'vehicleMake', component: VehicleMakeComponent },
            { path: 'vehicleMake/:id', component: VehicleMakeDetailComponent },
            {
                path: 'vehicleMake/:id/edit',
                canDeactivate: [VehicleMakeEditGuard],
                component: VehicleMakeEditComponent
            }
        ])
    ],
    declarations: [
        VehicleMakeComponent,
        VehicleMakeDetailComponent,
        VehicleMakeEditComponent
    ]
})

export class VehicleMakeModule { }
