import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpXhrBackend, HttpClientModule, HttpBackend, XhrFactory, HTTP_INTERCEPTORS } from '@angular/common/http'
import { routing } from './app.routing';

/* App Root */
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';

/* Account Imports */
import { AccountModule } from './account/account.module';
/* Dashboard Imports */
import { DashboardModule } from './dashboard/dashboard.module';

import { ConfigService } from './shared/utils/config.service';
import { FarmModule } from './dashboard/pages/farm/farm.module';
import { FieldModule } from './dashboard/pages/field/field.module';
import { HttpAuthenticationInterceptorHandler } from './shared/utils/interceptor-handler';
import { FarmService } from './dashboard/services/farm.service';
import { FieldService } from './dashboard/services/field.service';
import { RentalAgreementService } from './shared/services/rentalAgreement.service';
import { HighlightJsModule, HighlightJsService } from 'angular2-highlight-js';
import { TraceModule } from './dashboard/pages/trace/trace.module';
import { TraceService } from './dashboard/services/trace.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { PrimeNgModule } from './primeng.module';
import { ProductModule } from './dashboard/pages/product/product.module';
import { ProductService } from './dashboard/services/product.service';
import { ProductTypeService } from './shared/services/productType.service';
import { PlantService } from './dashboard/services/plant.service';
import { PlantVarietyService } from './dashboard/services/plant-variety.service';
import { TraceProductService } from './dashboard/services/trace-product.service';
import { EmployeeModule } from './dashboard/pages/employee/employee.module';
import { EmployeeService } from './dashboard/services/employee.service';
import { CompanyService } from './shared/services/company.service';
import { CompanyModule } from './dashboard/pages/company/company.module';
import { DatePipe } from '@angular/common';
import { EmployeeTripService } from './dashboard/services/employeetrip.service';
import { VehicleMakeService } from './dashboard/services/vehicle-make.service';
import { VehicleModelService } from './dashboard/services/vehicle-model.service';
import { VehicleService } from './dashboard/services/vehicle.service';
import { VehicleModule } from './dashboard/pages/vehicle/vehicle.module';
import { FileUploadServiceService } from './dashboard/services/file-upload-service.service';
import { EmployeeTripModule } from './dashboard/pages/employeetrip/employee-trip.module';
import { VehicleModelModule } from './dashboard/pages/vehicle-model/vehicle-model.module';
import { VehicleMakeModule } from './dashboard/pages/vehicle-make/vehicle-make.module';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HomeComponent
        
        
    ],
    imports: [
        AccountModule,
        DashboardModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        routing,
        HighlightJsModule,
        FarmModule,
        EmployeeModule,
        EmployeeTripModule,
        VehicleModule,
        VehicleMakeModule,
        VehicleModelModule,
        CompanyModule,
        FieldModule,
        TraceModule,
        ProductModule,
        BrowserAnimationsModule,
        MaterialModule,
        PrimeNgModule
    ],
    providers: [ConfigService, { provide: HTTP_INTERCEPTORS, useClass: HttpAuthenticationInterceptorHandler, multi: true }
        , FarmService
        , EmployeeService
        , EmployeeTripService
        , CompanyService
        , FieldService
        , TraceService
        , RentalAgreementService
        , HighlightJsService,
        , ProductService
        , ProductTypeService
        , PlantService
        , VehicleService
        , VehicleModelService
        , VehicleMakeService
        , TraceProductService
        , DatePipe
        , FileUploadServiceService
        , PlantVarietyService],

    bootstrap: [AppComponent]
})
export class AppModule { }
