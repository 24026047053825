import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs'
import { map, tap, catchError } from 'rxjs/operators'
import { Field } from '../../data-models/field';

@Injectable()
export class FieldService {
  private fieldsUrl = 'api/fields';

  constructor(private http: HttpClient, ) { }
  getFields(): Observable<Field[]> {
    return this.http.get<Field[]>(this.fieldsUrl + '/GetFields')
      .pipe(
        tap(data => JSON.stringify(data)),
        catchError(this.handleError)
      );
  }

  getField(id: number): Observable<Field> {
    if (id === 0) {
      return of(this.initializeField());
    }
    const url = `${this.fieldsUrl}/${id}`;
    
    return this.http.get<Field>(url)
      .pipe(
      tap(data =>JSON.stringify(data)),
        catchError(this.handleError)
      );
  }

  createField(field: Field): Observable<Field> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    field.id = null;
    return this.http.post<Field>(this.fieldsUrl + '/SaveField', JSON.stringify(field), { headers })
      .pipe(
      tap(data => console.log('createField: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  deleteField(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.fieldsUrl}/${id}`;
    return this.http.delete<Field>(url, { headers })
      .pipe(
      tap(data => console.log('deleteField: ' + id)),
        catchError(this.handleError)
      );
  }

  updateField(field: Field): Observable<Field> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.fieldsUrl}/${field.id}`;
    return this.http.put<Field>(url, field, { headers })
      .pipe(
      tap(() => console.log('updateField: ' + field.id)),
        // Return the field on an update
        map(() => field),
        catchError(this.handleError)
      );
  }

  private handleError(err) {
    debugger;
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }

  private initializeField(): Field {
    // Return an initialized object
    return {
      id: 0,
      fieldName: null,
      code: null,
      rowSpacing: null,
      rentalAgreementId: null,
      size: null,
      farmId: null,
      farmName :null,
      rentalAgreementText:null,
      rentalAgreementName:null

    };
  }
}
