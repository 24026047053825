import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { RouterModule } from '@angular/router';
import { TraceComponent } from './trace.component';
import { TraceDetailComponent } from './trace-detail.component';
import { TraceGuard } from './trace.guard';
import { TraceEditComponent } from './trace-edit.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';



@NgModule({
  declarations: [TraceComponent, TraceDetailComponent, TraceEditComponent],
  imports: [
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    DataTablesModule,
    RouterModule.forChild([
      { path: 'traces', component: TraceComponent },
      { path: 'traces/:id', component: TraceDetailComponent },
      {
        path: 'traces/:id/edit',
        canDeactivate: [TraceGuard],
        component: TraceEditComponent
      }
    ])
  ]
})
export class TraceModule { }
