import { Injectable } from '@angular/core';
import { Plant } from 'src/app/data-models/plant';
import { throwError, Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { TraceProduct } from 'src/app/data-models/trace-product';

@Injectable({
  providedIn: 'root'
})
export class TraceProductService {
    private traceProductsUrl = 'api/traceProducts';

    constructor(private http: HttpClient, ) { }
    getTraceProducts(): Observable<TraceProduct[]> {
        return this.http.get<TraceProduct[]>(this.traceProductsUrl + '/GetTraceProducts')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    getTraceProduct(id: number): Observable<TraceProduct> {
        if (id === 0) {
            return of(this.initializeTraceProduct());
        }
        const url = `${this.traceProductsUrl}/${id}`;

        return this.http.get<TraceProduct>(url)
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    createTraceProduct(traceProduct: TraceProduct): Observable<TraceProduct> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<TraceProduct>(this.traceProductsUrl + '/SaveTraceProduct', JSON.stringify(traceProduct), { headers })
            .pipe(
                tap(data => console.log('createTraceProduct: ' + JSON.stringify(data))),
                catchError(this.handleError)
            );
    }

    deleteTraceProduct(id: number): Observable<{}> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.traceProductsUrl}/${id}`;
        return this.http.delete<TraceProduct>(url, { headers })
            .pipe(
                tap(data => console.log('deleteTraceProduct: ' + id)),
                catchError(this.handleError)
            );
    }

    updateField(traceProduct: TraceProduct): Observable<TraceProduct> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.traceProductsUrl}/${traceProduct.productId}`;
        return this.http.put<TraceProduct>(url, traceProduct, { headers })
            .pipe(
                tap(() => console.log('updateTraceProduct: ' + traceProduct.productId)),
                // Return the field on an update
                map(() => traceProduct),
                catchError(this.handleError)
            );
    }

    private handleError(err) {
        debugger;
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }

    private initializeTraceProduct(): TraceProduct {
        // Return an initialized object
        return {
            productId: 0,
            tractId: 0,
            addedDate: null,
            allocationPrice: null,
            allocationZimPrice: null,
            quantity: null,
            
        };
    }
}
