import { Component, OnInit, ViewChildren, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName, FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Farm } from '../../../data-models/farm';
import { Subscription, Observable, fromEvent, merge } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FarmService } from '../../services/farm.service';
import { GenericValidator } from '../../../shared/generic-validator';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-farm.detail',
  templateUrl: './farm.detail.component.html',
  styleUrls: ['./farm.detail.component.scss']
})
export class FarmDetailComponent  implements OnInit {
  pageTitle = 'Farm Detail';
  errorMessage = '';
  farm: Farm | undefined;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private farmService: FarmService) {
  }

  ngOnInit() {
    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      this.getFarm(id);
    }
  }

  getFarm(id: number) {
    this.farmService.getFarm(id).subscribe({
      next: farm => this.farm = farm,
      error: err => this.errorMessage = err
    });
  }

  onBack(): void {
      this.router.navigate(['/dashboard/farm']);
  }

}
