import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseService } from "./base.service";
import { Observable, throwError, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { ProductType } from "src/app/data-models/productType";

@Injectable(
  
)
export class ProductTypeService {
  private productTypesUrl = 'api/productTypes';
  
  constructor(private http: HttpClient, ) { }
  getProductTypes(): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(this.productTypesUrl + '/GetProductTypes')
      .pipe(
        tap(data => JSON.stringify(data)),
        catchError(this.handleError)
      );
  }

  getProductType(id: number): Observable<ProductType> {
    if (id === 0) {
      return of(this.initializeProductType());
    }
    const url = `${this.productTypesUrl}/${id}`;

    return this.http.get<ProductType>(url)
      .pipe(
        tap(data => JSON.stringify(data)),
        catchError(this.handleError)
      );
  }

  createProductType(productType: ProductType): Observable<ProductType> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    productType.id = null;
    return this.http.post<ProductType>(this.productTypesUrl + '/SaveProductType', JSON.stringify(productType), { headers })
      .pipe(
      tap(data => console.log('createProductType: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  deleteProductType(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.productTypesUrl}/${id}`;
    return this.http.delete<ProductType>(url, { headers })
      .pipe(
      tap(data => console.log('deleteProductType: ' + id)),
        catchError(this.handleError)
      );
  }

  updateProductType(productType: ProductType): Observable<ProductType> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.productTypesUrl}/${productType.id}`;
    return this.http.put<ProductType>(url, productType, { headers })
      .pipe(
      tap(() => console.log('updateProductType: ' + productType.id)),
        // Return the RentalAgreement on an update
      map(() => productType),
        catchError(this.handleError)
      );
  }

  private handleError(err) {
    debugger;
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }

  private initializeProductType(): ProductType{
    // Return an initialized object
    return {
      id: 0,
      description: null,
      name: null,
      parentId: null
      
    };
  }
}
