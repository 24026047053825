import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { EmployeetripEditComponent } from './employeetrip-edit.component';

@Injectable({
  providedIn: 'root'
})
export class EmployeetripGuard implements CanDeactivate<EmployeetripEditComponent> {
    canDeactivate(component: EmployeetripEditComponent): Observable<boolean> | Promise<boolean> | boolean {
        if (component.employeeTripForm.dirty) {
            const employeeName = component.employeeTripForm.get('employeeName').value || 'New Trip';
            return confirm(`Navigate away and lose all changes to ${employeeName}?`);
        }
        return true;
    }
}