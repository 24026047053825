import { Injectable } from '@angular/core';
import { throwError, Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { VehicleModel } from '../../data-models/vehiclemodel';

@Injectable({
  providedIn: 'root'
})
export class VehicleModelService {
    private vehicleModelUrl = 'api/VehicleModel';

    constructor(private http: HttpClient,) { }
    getVehicleModels(): Observable<VehicleModel[]> {
        return this.http.get<VehicleModel[]>(this.vehicleModelUrl + '/GetVehicleModels')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }
    
    getVehicleModel(id: number): Observable<VehicleModel> {
        if (id === 0) {
            return of(this.initializeVehicleModel());
        }
        const url = `${this.vehicleModelUrl}/${id}`;

        return this.http.get<VehicleModel>(url)
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    createVehicleModel(vehicleModel: VehicleModel): Observable<VehicleModel> {
        
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        vehicleModel.id = null;
        return this.http.post<VehicleModel>(this.vehicleModelUrl + '/SaveVehicleModel', JSON.stringify(vehicleModel), { headers })
            .pipe(
                tap(data => console.log('createVehicleModel: ' + JSON.stringify(data))),
                catchError(this.handleError)
            );
    }

    deleteVehicleModel(id: number): Observable<{}> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.vehicleModelUrl}/${id}`;
        return this.http.delete<VehicleModel>(url, { headers })
            .pipe(
                tap(data => console.log('deleteVehicleModel: ' + id)),
                catchError(this.handleError)
            );
    }

    updateVehicleModel(vehicleModel: VehicleModel): Observable<VehicleModel> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.vehicleModelUrl}/${vehicleModel.id}`;
        return this.http.put<VehicleModel>(url, vehicleModel, { headers })
            .pipe(
                tap(() => console.log('updateVehicleModel: ' + vehicleModel.id)),
                // Return the field on an update
                map(() => vehicleModel),
                catchError(this.handleError)
            );
    }

    private handleError(err) {
        debugger;
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }

    private initializeVehicleModel(): VehicleModel{
        // Return an initialized object
        return {
            id: 0,
            name: null,
            makeId: null,
            vehicleMake:null
        };
    }
}

