import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseService } from "./base.service";
import { Observable, throwError, of } from "rxjs";
import { RentalAgreement } from "../../data-models/rentalAgreement";
import { map, catchError, tap } from "rxjs/operators";

@Injectable(
  
)
export class RentalAgreementService {
  private rentalAgreementsUrl = 'api/rentalAgreements';
  
  constructor(private http: HttpClient, ) { }
  getRentalAgreements(): Observable<RentalAgreement[]> {
    return this.http.get<RentalAgreement[]>(this.rentalAgreementsUrl + '/GetRentalAgreements')
      .pipe(
        tap(data => JSON.stringify(data)),
        catchError(this.handleError)
      );
  }

  getRentalAgreement(id: number): Observable<RentalAgreement> {
    if (id === 0) {
      return of(this.initializeRentalAgreement());
    }
    const url = `${this.rentalAgreementsUrl}/${id}`;

    return this.http.get<RentalAgreement>(url)
      .pipe(
        tap(data => JSON.stringify(data)),
        catchError(this.handleError)
      );
  }

  createRentalAgreement(rentalAgreement: RentalAgreement): Observable<RentalAgreement> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    rentalAgreement.id = null;
    return this.http.post<RentalAgreement>(this.rentalAgreementsUrl + '/SaveRentalAgreement', JSON.stringify(rentalAgreement), { headers })
      .pipe(
      tap(data => console.log('createRentalAgreement: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  deleteRentalAgreement(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.rentalAgreementsUrl}/${id}`;
    return this.http.delete<RentalAgreement>(url, { headers })
      .pipe(
      tap(data => console.log('deleteRentalAgreement: ' + id)),
        catchError(this.handleError)
      );
  }

  updateRentalAgreement(rentalAgreement: RentalAgreement): Observable<RentalAgreement> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.rentalAgreementsUrl}/${rentalAgreement.id}`;
    return this.http.put<RentalAgreement>(url, rentalAgreement, { headers })
      .pipe(
      tap(() => console.log('updateRentalAgreement: ' + rentalAgreement.id)),
        // Return the RentalAgreement on an update
      map(() => rentalAgreement),
        catchError(this.handleError)
      );
  }

  private handleError(err) {
    debugger;
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }

  private initializeRentalAgreement(): RentalAgreement {
    // Return an initialized object
    return {
      id: 0,
      agreementText: null,
      name: null,
      userId: null,
      modifiedBy: null,
      modifiedDate: null,
      capturedDate: null
      
    };
  }
}
