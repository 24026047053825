import { Injectable } from '@angular/core';
import { Plant } from 'src/app/data-models/plant';
import { throwError, Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { TraceProduct } from 'src/app/data-models/trace-product';
import { Product } from 'src/app/data-models/product';

@Injectable()
export class ProductService {
    private productsUrl = 'api/Product';

    constructor(private http: HttpClient, ) { }
    getProducts(): Observable<Product[]> {
        return this.http.get<Product[]>(this.productsUrl + '/GetProducts')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }
    
    getProductByProductTypeAndReference(productTypeId: number, reference: number): Observable<Product> {

        if (productTypeId === 0 && reference===0) {
            return of(this.initializeProduct());
        }
        var product = this.initializeProduct();
        const url = `${this.productsUrl}/GetProductByProductTypeAndReference`;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        product.productTypeId = productTypeId;
        product.reference = reference as any;
        return this.http.post<Product>(url, JSON.stringify(product), { headers })

            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }
    getProductsByProductType(id: number): Observable<Product[]> {

        if (id === 0) {
            //return of(this.initializeVehicleMake());
        }
        return this.http.get<Product[]>(this.productsUrl + '/' + id + '/GetProductsByProductType')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }
    getProduct(id: number): Observable<Product> {
        if (id === 0) {
            return of(this.initializeProduct());
        }
        const url = `${this.productsUrl}/${id}`;

        return this.http.get<Product>(url)
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    createProduct(product: Product): Observable<Product> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        product.id = null;
        return this.http.post<Product>(this.productsUrl + '/SaveProduct', JSON.stringify(product), { headers })
            .pipe(
                tap(data => console.log('createProduct: ' + JSON.stringify(data))),
                catchError(this.handleError)
            );
    }

    deleteProduct(id: number): Observable<{}> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.productsUrl}/${id}`;
        return this.http.delete<Product>(url, { headers })
            .pipe(
                tap(data => console.log('deleteProduct: ' + id)),
                catchError(this.handleError)
            );
    }

    updateProduct(product: Product): Observable<Product> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.productsUrl}/${product.id}`;
        return this.http.put<Product>(url, product, { headers })
            .pipe(
                tap(() => console.log('updateProduct: ' + product.id)),
                // Return the field on an update
                map(() => product),
                catchError(this.handleError)
            );
    }

    private handleError(err) {
        debugger;
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }
    
    private initializeProduct(): Product {
        // Return an initialized object
        return {
            id: 0,
            name: null,
            code: null,
            brandId: null,
            description: null,
            price: null,
            productTypeId: null,
            reference: null,
            zimPrice: null            

        };
    }
}
