import { Component, OnInit } from '@angular/core';
import { EmployeeTrip } from '../../../data-models/employeetrip';
import { ConfigService } from '../../../shared/utils/config.service';
import { EmployeeTripService } from '../../services/employeetrip.service';

@Component({
  selector: 'app-employeetrip',
  templateUrl: './employeetrip.component.html',
  styleUrls: ['./employeetrip.component.css']
})
export class EmployeetripComponent implements OnInit {

    pageTitle = 'Trip List';
    imageWidth = 50;
    imageMargin = 2;
    showImage = false;
    errorMessage = '';
    url: string;

    _listFilter = '';
    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredEmployeeTrips = this.listFilter ? this.performFilter(this.listFilter) : this.employeeTrips;
    }

    filteredEmployeeTrips: EmployeeTrip[] = [];
    employeeTrips: EmployeeTrip[] = [];
    //dtOptions: DataTables.Settings = {};
    dtOptions: any = {};

    constructor(private employeeTripService: EmployeeTripService,
                private configService: ConfigService        ) {
        this.url = configService.getBaseURI();
    }

    performFilter(filterBy: string): EmployeeTrip[] {

        filterBy = filterBy.toLocaleLowerCase();
        return this.employeeTrips.filter((employeeTrip: EmployeeTrip) =>
            employeeTrip.employeeName.toLocaleLowerCase().indexOf(filterBy) !== -1
            ||
            employeeTrip.vehicleName.toLocaleLowerCase().indexOf(filterBy) !== -1 ||
            employeeTrip.productName.toLocaleLowerCase().indexOf(filterBy) !== -1
        );
    }

    toggleImage(): void {
        this.showImage = !this.showImage;
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 20,
            processing: true,
            dom: 'Bfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'print'
            ]
        };
        this.employeeTripService.getEmployeeTrips().subscribe({
            next: item => {

                this.employeeTrips = item;
                this.filteredEmployeeTrips = this.employeeTrips;

            },
            error: err => this.errorMessage = err
        });
    }

    openImage(link: any) {


        const myWindow = window.open(
            this.url + "/" + link,
            '_blank',
            'width=900, height=240'
        );
    }
}
