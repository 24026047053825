import { Injectable } from '@angular/core';
import { Vehicle } from 'src/app/data-models/vehicle';
import { throwError, Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class VehicleService {
    private vehicleUrl = 'api/Vehicle';

    constructor(private http: HttpClient,) { }
    getVehicles(): Observable<Vehicle[]> {
        return this.http.get<Vehicle[]>(this.vehicleUrl + '/GetVehicles')
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }
    getVehicleByModelId(id: number): Observable<Vehicle[]> {
        return this.http.get<Vehicle[]>(this.vehicleUrl + '/ListVehicleByModelId/' + id)
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }
    getVehicle(id: number): Observable<Vehicle> {
        if (id === 0) {
            return of(this.initializeVehicle());
        }
        const url = `${this.vehicleUrl}/${id}`;

        return this.http.get<Vehicle>(url)
            .pipe(
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    createVehicle(vehicle: Vehicle): Observable<Vehicle> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        vehicle.id = null;
        return this.http.post<Vehicle>(this.vehicleUrl + '/SaveVehicle', JSON.stringify(vehicle), { headers })
            .pipe(
                tap(data => console.log('createVehicle: ' + JSON.stringify(data))),
                catchError(this.handleError)
            );
    }

    deleteVehicle(id: number): Observable<{}> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.vehicleUrl}/${id}`;
        return this.http.delete<Vehicle>(url, { headers })
            .pipe(
                tap(data => console.log('deleteVehicle: ' + id)),
                catchError(this.handleError)
            );
    }

    updateVehicle(vehicle: Vehicle): Observable<Vehicle> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.vehicleUrl}/${vehicle.id}`;
        return this.http.put<Vehicle>(url, vehicle, { headers })
            .pipe(
                tap(() => console.log('updateVehicle: ' + vehicle.id)),
                // Return the field on an update
                map(() => vehicle),
                catchError(this.handleError)
            );
    }

    private handleError(err) {
        
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }

    private initializeVehicle(): Vehicle{
        // Return an initialized object
        return {
            id: 0,
            modelId: null,
             distanceTravelled: null,
            ordometerReading: null,
            previousReading: null,
            registrationNumber: null,
            vehicleMake: null,
            vehicleModel: null,
            vin: null

        };
    }
}

