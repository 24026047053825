import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';


// Imports for loading & configuring the in-memory web api
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { SharedModule } from '../../../shared/modules/shared.module';

import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { EmployeeDetailComponent } from './employee-detail.component';
import { EmployeeComponent } from './employee.component';
import { EmployeeEditComponent } from './employee-edit.component';

import { EmployeeEditGuard } from './employee-edit.guard';

@NgModule({
    imports: [
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        DataTablesModule,
        RouterModule.forChild([
            { path: 'employee', component: EmployeeComponent },
            { path: 'employee/:id', component: EmployeeDetailComponent },
            {
                path: 'employee/:id/edit',
                canDeactivate: [EmployeeEditGuard],
                component: EmployeeEditComponent
            }
        ])
    ],
    declarations: [
        EmployeeComponent,
        EmployeeDetailComponent,
        EmployeeEditComponent
    ]
})
export class EmployeeModule { }
