import { Component, OnInit } from '@angular/core';

import { HomeDetails } from '../models/home.details.interface';
import { DashboardService } from '../services/dashboard.service';
import { Home } from 'src/app/data-models/home';
import { EmployeeTrip } from '../../data-models/employeetrip';
import { EmployeeTripService } from '../services/employeetrip.service';
import { ConfigService } from '../../shared/utils/config.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    homeDetails: Home;
    _listFilter = '';
    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredEmployeeTrips = this.listFilter ? this.performFilter(this.listFilter) : this.employeeTrips;
    }

    filteredEmployeeTrips: EmployeeTrip[] = [];
    employeeTrips: EmployeeTrip[] = [];
    errorMessage = '';
    url: string;
    dtOptions: any= {};

    constructor(private dashboardService: DashboardService,
        private employeeTripService: EmployeeTripService,
        private configService: ConfigService    ) {
        this.url = configService.getBaseURI();
    }
    performFilter(filterBy: string): EmployeeTrip[] {

        filterBy = filterBy.toLocaleLowerCase();
        return this.employeeTrips.filter((employeeTrip: EmployeeTrip) =>
            employeeTrip.employeeName.toLocaleLowerCase().indexOf(filterBy) !== -1
            ||
            employeeTrip.vehicleName.toLocaleLowerCase().indexOf(filterBy) !== -1 ||
            employeeTrip.productName.toLocaleLowerCase().indexOf(filterBy) !== -1
        );
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 20,
            processing: true,
            dom: 'Bfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'print'
            ]
        };
        this.employeeTripService.getEmployeeTrips().subscribe({
            next: item => {

                this.employeeTrips = item;
                this.filteredEmployeeTrips = this.employeeTrips;

            },
            error: err => this.errorMessage = err
        });
    }
    openImage(link: any) {


        const myWindow = window.open(
            this.url + "/" + link,
            '_blank',
            'width=900, height=240'
        );
    }
}
